import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const DialogIndikatorRevisi = ({openRevisi,handleCloseRevisi,indikatorId,updateIndikatorById,submitRevisi,indikatorData,selectDana}) => {
  const [paguData,setPaguData] = useState({pagu:true,pendanaan:true});

  const setIndikatorId = async (e) => {
      const { name, value } = e.target;     
      if(name === 'perencanaan'){
        setPaguData({...paguData,pagu:false});
      }else if(name === 'program'){
        setPaguData({...paguData,pendanaan:false});
      }
      const newDataIndikatorId = { ...indikatorId, [name]: value };
      await updateIndikatorById(newDataIndikatorId); // Panggil fungsi untuk mengupdate data
    };

    useEffect(()=>{
      if (openRevisi  && indikatorData === 'program') {
        setPaguData({...paguData,pagu:true,pendanaan:true}); // Reset paguData setiap kali dialog dibuka
      }else if(indikatorData !== 'program'){
        setPaguData({...paguData,pagu:false,pendanaan:false});
      }
    },[openRevisi]);

    return (
      <>
        {/* =========================== Dialog ================================== */}
        <Dialog
          open={openRevisi}
          fullWidth
          maxWidth="md"
          onClose={handleCloseRevisi}
        >
          <DialogTitle>Revisi Perencanaan</DialogTitle>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                Sisa Pagu/Perencanaan :
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  label="Perencanaan"
                  name="perencanaan"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={paguData.pagu === true
                  ? selectDana?.pagu || '' : indikatorId?.perencanaan || ''}
                  onChange={setIndikatorId}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  label="Kegiatan"
                  name={
                    indikatorData === "program"
                      ? "program"
                      : indikatorId.kode === "program"
                      ? "program"
                      : indikatorId.kode === "kegiatan"
                      ? "kegiatan"
                      : indikatorId.kode === "sub_kegiatan"
                      ? "sub_kegiatan"
                      : ""
                  }
                  type="text"
                  fullWidth
                  variant="standard"
                  value={
                    paguData.pendanaan === true
                      ? selectDana.pendanaan || '' :
                      (indikatorId.kode === "program"
                      ? indikatorId.program
                      : indikatorId.kode === "kegiatan"
                      ? indikatorId.kegiatan
                      : indikatorId.kode === "sub_kegiatan"
                      ? indikatorId.sub_kegiatan
                      : "")
                  }
                  onChange={setIndikatorId}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRevisi}>Cancel</Button>
            <Button onClick={ !paguData.pagu ? submitRevisi : handleCloseRevisi}>Send</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  
  export default DialogIndikatorRevisi;