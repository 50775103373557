import React,{useEffect, useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { getParent,createPendanaan } from '../../store/master';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, MenuList, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from '@mui/material';
import {LibraryAdd, MoreVert} from '@mui/icons-material';
import { formatRupiah, rupiah } from '../../function/FormatRupiah';
import { RowTop, TableTop } from '../../assets/styled';
import { logoutUser } from '../../store/user';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)({
    backgroundColor:'#4875EE',
    color:'#ffffff',
    margin:'10px',
  })

  const TableCells = styled(TableCell)({
    border: "1px solid black",
  });

const Pendanaan = () => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    useEffect(() => {
      const savedTahun = localStorage.getItem('tahun');
      
      if (savedTahun === undefined || savedTahun === null) {
        // Jika nilai tahun masih undefined atau null, panggil localStorage lagi
        let interval = setInterval(() => {
          const newTahun = localStorage.getItem('tahun');
          if (newTahun !== undefined && newTahun !== null) {
            clearInterval(interval); // Hentikan interval jika nilai sudah ditemukan
            dispatch(getParent(newTahun))
            .then((res)=>{
              // ====== hasil authentikasi token =========
                if(res.status === 'error'){
                  dispatch(logoutUser());
                  navigate('/login');
              }
              //============================================
            });
          }
        }, 1000); // Panggil setiap 1 detik (atau sesuai kebutuhan)
      } else {
        // Jika nilai tahun sudah ada, lakukan dispatch langsung
        dispatch(getParent(savedTahun))
        .then((res)=>{
          // ====== hasil authentikasi token =========
            if(res.status === 'error'){
              dispatch(logoutUser());
              navigate('/login');
          }
          //============================================
        });;
      }
    }, [dispatch,navigate]);
    

    function createData(id_pendanaan,pendanaan,pagu,parent,level,child) {
        return { id_pendanaan,pendanaan,pagu,parent,level,child };
      }
    const pendanaanData = [];

    const pendanaan = useSelector(state => state.Master.pendanaan);

    pendanaan.forEach(row => {
        pendanaanData.push(createData(row.id_pendanaan,row.pendanaan,row.pagu,row.parent,row.level,row.child));
    });

    const submit = async() =>{
      await dispatch(createPendanaan(dataVal));
      await handleClose();
      await setDataVal({...dataVal,pendanaan:'',pagu:'',parent:'',level:''})
    }


      //===================Open Dialog ===============================
      const [open, setOpen] = useState(false);

      const [dataVal,setDataVal] = useState({pendanaan:'',pagu:'',parent:'',level:''});

      const handleClickOpen = async() => {
        await setDataVal({...dataVal,parent:0,level:1});
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

        function handleInputChange(value) {
          const formattedValue = formatRupiah(value);
          setDataVal({...dataVal,pagu:formattedValue});
        }

        function handleKeyPress(e) {
          const charCode = e.charCode;
          if (charCode >= 48 && charCode <= 57) {
            e.preventDefault();
          }
        }
      
       //=================Menu Item ============================
        const [anchorEl,setAnchorEl] = useState(null);
        const [idPendanaan,setIdPendanaan] = useState('');
        // const open = Boolean(anchorEl);

        const handleClick = (event)=>{
              const { id_pendanaan } = event.currentTarget.dataset;
              setIdPendanaan(id_pendanaan || '');
              anchorEl === null ? setAnchorEl(event.currentTarget):setAnchorEl(null);
        }

        const handleClose2 = async() => {
            await setAnchorEl(null);
        };


        const addPagu = async(id_pendanaan) =>{
          await setDataVal({...dataVal,parent:id_pendanaan,level:2});
          setOpen(true);
          handleClose2();
        }


        //==================== proses child ===========================================================
        const renderChildRow = (child) =>{
         return child.map((row)=>(
                <TableRow
                        key={row.id_pendanaan}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCells align='center' colSpan={2}  >{row.pendanaan}</TableCells>
                        <TableCells align='left' colSpan={2} >{rupiah(row.pagu)}</TableCells>
                    </TableRow>
          ))
        }

  return (
    <>
    <Typography variant="h4" sx={{mb:4}} color="initial">
      Pendanaan
    </Typography>
    <Paper sx={{width: '100%', overflow:'hidden'}}>
    <Grid container spacing={2}>
        <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
          <Typography sx={{m:2,fontSize:20}}></Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <StyledButton 
              variant="contained"
              onClick={handleClickOpen}
            >  Add Pendanaan </StyledButton>
        </Grid>
      </Grid>
      <Divider />
      <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
              <RowTop>
                  <TableTop>#</TableTop>
                  <TableTop>Pendanaan</TableTop>
                  <TableTop>Pagu</TableTop>
                  <TableTop colSpan={2} >Opsi</TableTop>
              </RowTop>
              </TableHead>
              <TableBody>
              {pendanaanData.map((row,index) => (
                <React.Fragment key={row.id_pendanaan}>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCells align='left' >{index+1}</TableCells>
                    <TableCells align='left' >{row.pendanaan}</TableCells>
                    <TableCells align='left' >{rupiah(row.pagu)}</TableCells>
                    <TableCells align='left' >
                    <IconButton 
                            id={`menu-button${index}`}
                            data-id_pendanaan={row.id_pendanaan}
                            aria-controls={Boolean(anchorEl) ? `menu-button${index}`:undefined}
                            aria-haspopup="true"
                            aria-expanded={Boolean(anchorEl) ? 'true': undefined}
                            onClick={handleClick} >
                            <MoreVert />
                    </IconButton>
                        <Menu
                              id={`menu-drop${index}`}
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleClose2}
                              MenuListProps={{'aria-labelledby':`menu-button${index}`}} >
                                  <MenuList sx={{ width: 150, maxWidth: '100%' }}>
                                          <MenuItem onClick={(e)=>addPagu(idPendanaan)} disableRipple >
                                            <ListItemIcon>
                                              <LibraryAdd />
                                            </ListItemIcon>Add Pagu
                                          </MenuItem>
                                    </MenuList>
                        </Menu>
                    </TableCells>
                    </TableRow>
                    {renderChildRow(row.child)}
                    <TableRow>
                      <TableCells colSpan={4}>{""}</TableCells>
                    </TableRow>
                </React.Fragment>

                                     
              ))}
              </TableBody>
          </Table>
          </TableContainer>
    </Paper>
    
      <Dialog open={open} fullWidth maxWidth="xs" onClose={handleClose}>
          <DialogTitle>Create Pagu Anggaran </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                label="Sumber Pendanaan"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e)=>{setDataVal({...dataVal,pendanaan:e.target.value})}}
                sx={{marginBottom:'20px'}}
              />
              <TextField
                autoFocus
                label="Nilai Pagu"
                type="text"
                fullWidth
                variant="standard"
                value={dataVal.pagu}
                onChange={(e)=>{handleInputChange(e.target.value)}}
                onKeyDown={handleKeyPress}
              />
            </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={submit}>Send</Button>
          </DialogActions>
        </Dialog>

    </>
  )
}

export default Pendanaan