import React,{useEffect, useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { getSatuan,createSatuan,deleteSatuan } from '../../store/satuan';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from '@mui/material';
import {Delete} from '@mui/icons-material';
import { logoutUser } from '../../store/user';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)({
  backgroundColor:'#4875EE',
  color:'#ffffff',
  margin:'10px',
})

const Satuan = () => {

    const dispatch = useDispatch();
    const [satuanVal,setSatuanVal] = useState('');
    const navigate = useNavigate();

    function createData(id_satuan,satuan) {
        return { id_satuan,satuan };
      }
      
      const rowData = [];

      useEffect(()=>{
        dispatch(getSatuan())
        .then(
          (res)=>{
            if(res.status === 'error'){
                dispatch(logoutUser());
                navigate('/login');
            }
          }
        );
      },[dispatch,navigate]);
      
      const satuan = useSelector(state => state.Satuan.satuan);
      

      satuan?.forEach(row => {
            rowData?.push(createData(row.id_satuan,row.satuan));
      });

      //===================Open Dialog ===============================
      const [open, setOpen] = useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const submitSatuan = async(e) =>{
        e.preventDefault();
        await dispatch(
          createSatuan({satuan:satuanVal})
        )
        .then(
          (res)=>{
            if(res.status === 'error'){
                dispatch(logoutUser());
                navigate('/login');
            }
          }
        );
        await handleClose();
      }

      const deleteHandler = async(id_satuan) =>{
        if(window.confirm('Anda Yakin ?')){
          await dispatch(deleteSatuan({id_satuan:id_satuan}));
        }
      }

  return (
    <>
    <Typography variant="h4" sx={{mb:4}} color="initial">
      Satuan
    </Typography>
    <Paper sx={{width: '100%', overflow:'hidden'}}>
    <Grid container spacing={2}>
        <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
          <Typography sx={{m:2,fontSize:20}}>User Data</Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <StyledButton 
              onClick={handleClickOpen}
            >  Tambah Satuan </StyledButton>
        </Grid>
      </Grid>
      <Divider />
      <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
              <TableRow>
                  <TableCell align="left">#</TableCell>
                  <TableCell align="left">Satuan</TableCell>
                  <TableCell align="left">Opsi</TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {rowData.map((row,index) => (
                  <TableRow
                  key={row.id_satuan}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                  <TableCell align='left' >{index+1}</TableCell>
                  <TableCell align='left' >{row.satuan}</TableCell>
                  <TableCell align='left' ><Delete sx={{ color: 'red' }} onClick={(e)=>deleteHandler(row.id_satuan)} /></TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
    </Paper>
    <Dialog open={open} fullWidth maxWidth="xs" onClose={handleClose}>
        <DialogTitle>Tambah Satuan</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Satuan"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e)=>setSatuanVal(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={submitSatuan}>Send</Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default Satuan