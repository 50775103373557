import React,{useEffect, useState} from 'react'
import {Button, Card, CardContent, CircularProgress, CssBaseline, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField, Typography} from '@mui/material'
import { Box } from '@mui/system'
import {VisibilityOffOutlined, VisibilityOutlined} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../store/user';
import loginImg from '../assets/loginImg2.gif'; 


const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [eyeOpen,setEyeOpen] = useState(false);

  const loginStatus = useSelector(state => state.User.loginStatus);


  const [userSpm,setUserSpm] = useState({username:'',password:''});

  const [loading,setLoading] = useState(false);

  useEffect(()=>{
    // console.log(loginStatus);
    if(loginStatus[0]?.islogin === true){
      setLoading(false);
      localStorage.setItem('isLogin',true);
      localStorage.setItem('hak_akses',loginStatus[0]?.hak_akses);
      localStorage.setItem('kode_bidang',loginStatus[0]?.kode_bidang ? loginStatus[0]?.kode_bidang : '');
      localStorage.setItem('tahun',new Date().getFullYear());
      navigate('/');
    }else{
      setLoading(false);
    }
  },[loginStatus,navigate]);
  
  const loginAuth = async(e) =>{
    e.preventDefault();
    setLoading(true);
    await dispatch(loginUser(userSpm));
  }

  return (
    <>
    <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${loginImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>  t.palette.background.default , 
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{borderRadius:'20px'}}>
        <Box sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', }}>
          <Box sx={{ mb: 2,mt:2 , justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <img src={require('../assets/logo_prov_kalsel.png')} alt="Logo" style={{ height: '100px' }} />
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='h6' sx={{ mb: 1.5, mt: 2, fontWeight: 800, letterSpacing: '0.18px',color:'grey' }}>
              {`DINAS SOSIAL PROV KALSEL`}<br/>
              {`STANDAR PELAYANAN MINIMUN (SPM)`}
            </Typography>
            <Divider sx={{ backgroundColor: 'grey', mb:2 }} />
          </Box>
              <form onSubmit={loginAuth}>
                <TextField
                  id=""
                  label="Username"
                  fullWidth
                  sx={{mb:4}}
                  onChange = {(event)=>setUserSpm({...userSpm,username:event.target.value})}
                />
                <FormControl fullWidth>
                  <InputLabel htmlFor='auth-login-password'>Password </InputLabel>
                    <OutlinedInput type={ eyeOpen ? 'text' : 'password' } label='Password' onChange = {(event)=>setUserSpm({...userSpm,password:event.target.value})} endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              edge='end'
                              aria-label='toggle password visibility'
                              onClick={()=>setEyeOpen(!eyeOpen)}
                            >
                              {eyeOpen ? <VisibilityOutlined /> : <VisibilityOffOutlined /> }
                            </IconButton>
                          </InputAdornment>
                        } />
                </FormControl>
                <Button fullWidth size='large' type='submit' variant='contained' sx={{ mb: 7, mt:4 }} disabled={ loading } > {loading ? <CircularProgress /> : ''} LOGIN</Button>
              </form>
              <Box sx={{ mb:0 , mt: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid item xs={4}>
                    <img src={require('../assets/bangga.png')} alt="Logo" style={{ width: '100%', maxWidth: '10vw', height: 'auto' }} />
                  </Grid>
                  <Grid item xs={4}>
                    <img src={require('../assets/berakhlak.png')} alt="Logo" style={{ width: '100%', maxWidth: '10vw', height: 'auto' }} />
                  </Grid>
                  <Grid item xs={4}>
                    <img src={require('../assets/bergerak.png')} alt="Logo" style={{ width: '100%', maxWidth: '10vw', height: 'auto' }} />
                  </Grid>
                </Grid>
              </Box>

        </Box>
        </Grid>
    </Grid>

    </>
    
  )
}

export default Login