import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { createCapaianKinerja, getCapaian, getSelectPendanaan } from "../../store/master";
import { useDispatch, useSelector } from "react-redux";
import { LibraryAdd, MoreVert } from "@mui/icons-material";
import ButtonMenuCapaian from "../../component/ButtonMenuCapaian";
import DialogCapaian from "../../component/DialogCapaian";
import { RowTop, TableTop } from "../../assets/styled";
// import makeStyles from '@mui/styles';

const TableCells = styled(TableCell)({
  border: "1px solid black",
});

const CapaianKinerja = () => {
  // const classes = useStyles();
  const dispatch = useDispatch();

  const [capaian, setCapaian] = useState([]);

  const [capaianInput, setCapaianInput] = useState({
    id_indikator: "",
    jenis: "",
    total_dilayani: "",
  });

  const dataCapaian = useSelector((state) => state.Master.capaian);

  const pendanaanSelected = useSelector(state => state.Master.selectPendanaan); 

  const tahunSelect = useSelector(state=>state.User.tahun);

  // ==== set pendanaan dari database =======
  useEffect(()=>{
    dispatch(getSelectPendanaan(tahunSelect)); 
    //localStorage.setItem('sumberDana',[]);
},[tahunSelect]);

//   useEffect(() => {
//     dispatch(getCapaian({ id_pendanaan: 2 })).then(setCapaian(dataCapaian));
//   }, []);

  const [callCount, setCallCount] = useState(0);
   useEffect(() => {
     if (capaian.length === 0 && callCount < 15) {
       try {
          //const sumberDanaLocalStorage = JSON.parse(localStorage.getItem('sumberDana'));
          //===== panggil data============
          setCallCount(prevCount => prevCount + 1);
            //dispatch(getCapaian({ id_pendanaan: sumberDanaLocalStorage.id_pendanaan }));
            pendanaanSelected[0]?.id_pendanaan && dispatch(getCapaian({ id_pendanaan: pendanaanSelected[0]?.id_pendanaan }));
            setCapaian(dataCapaian);
             if(capaian.length > 0){
               setCallCount(15);
             }
       } catch (error) {
          console.error("Error dalam request:", error);
       }
    }
 }, [capaian,callCount,dataCapaian,dispatch]);

  //=================Menu Item ============================
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectIndikator, setSelectIndikator] = useState([]);
  const handleClick = (event) => {
    const { id_indikator, id_pendanaan, jenis, kegiatan } = event.currentTarget.dataset;
    const newData = {
      id_indikator: id_indikator || "",
      id_pendanaan: id_pendanaan || "",
      jenis: jenis || "",
      kegiatan:kegiatan || ""
    };
    setSelectIndikator(newData);
    anchorEl === null ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
  };

  const handleClose2 = async () => {
    await setAnchorEl(null);
  };

  // ====================== Open Dialog ====================================
  const [open, setOpen] = useState(false);
  const handleClickOpen = async () => {
    setCapaianInput({
      ...capaianInput,
      id_indikator: selectIndikator.id_indikator,
      jenis: selectIndikator.jenis,
    });
    await setOpen(true);
    await handleClose2();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCapaian = (newValue) => {
    setCapaianInput({ ...capaianInput, total_dilayani: newValue });
  };

  const submitCapaian = async() => {
        await dispatch(createCapaianKinerja({capaian:capaianInput,id_pendanaan:selectIndikator.id_pendanaan}));
        await handleClose();
  };

  useEffect(() => {
    // console.log('dataPagu sebelum diperbarui:', dataPagu);
    setCapaian([]);
    // console.log('dataPagu setelah diperbarui:', dataPagu);
    setCapaian(dataCapaian);
    
  }, [dataCapaian]);

  const renderCapaian = (data) => {
    return data.map((row, index) => (
      <React.Fragment key={index + 1}>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCells>{row.kode === "kegiatan" ? index + 1 : ""}</TableCells>
          <TableCells align="left">
            {row.kode === "kegiatan"
              ? row.kegiatan
              : index + 1 + ". " + row.sub_kegiatan}
          </TableCells>
          <TableCells>
            {row.kode === "sub_kegiatan" ? row.satuan : ""}
          </TableCells>
          <TableCells>
            {row.kode === "sub_kegiatan" && row.total_dilayani}
          </TableCells>
          <TableCells>
            {row.kode === "sub_kegiatan" && (
              <ButtonMenuCapaian
                id_indikator={row.id_indikator}
                id_pendanaan={row.id_pendanaan}
                jenis={"mutu"}
                kegiatan={row.sub_kegiatan}
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose2={handleClose2}
                handleClickOpen={handleClickOpen}
              />
            )}
          </TableCells>
        </TableRow>
        {row.kode === "kegiatan" && (
          <>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCells>{""}</TableCells>
              <TableCells>
                PERSENTASE PENCAPAIAN PENERIMA LAYANAN DASAR (80%)
              </TableCells>
              <TableCells>{""}</TableCells>
              <TableCells>Jumlah Total Yang Harus Dilayani</TableCells>
              <TableCells></TableCells>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCells>{""}</TableCells>
              <TableCells> • Jumlah yang Harus Dilayani :</TableCells>
              <TableCells>{"orang"}</TableCells>
              <TableCells>
                {row && row.layanan && row.layanan.total_dilayani}
              </TableCells>
              <TableCells>
                <ButtonMenuCapaian
                  id_indikator={row.id_indikator}
                  id_pendanaan={row.id_pendanaan}
                  jenis={"layanan"}
                  kegiatan={"PENCAPAIAN PENERIMA LAYANAN DASAR (80%)"}
                  anchorEl={anchorEl}
                  handleClick={handleClick}
                  handleClose2={handleClose2}
                  handleClickOpen={handleClickOpen}
                />
              </TableCells>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCells>{""}</TableCells>
              <TableCells align="left" colSpan={4}>
                PERSENTASE PENCAPAIAN MUTU MINIMAL LAYANAN DASAR (20%)
              </TableCells>
            </TableRow>
            <TableRow>
              <TableCells>{""}</TableCells>
              <TableCells></TableCells>
              <TableCells></TableCells>
              <TableCells align="left" colSpan={2}>
                Jumlah warga negara yang berhak mendapat layanan
              </TableCells>
            </TableRow>
          </>
        )}
        {renderCapaian(row.mutu)}
      </React.Fragment>
    ));
  };

  return (
    <>
      <Typography variant="h4" sx={{ mb: 4 }} color="initial">
        CAPAIAN KINERJA
      </Typography>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography sx={{ m: 2, fontSize: 20 }}>SET CAPAIAN KINERJA</Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end"></Grid>
        </Grid>
        <Divider />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <RowTop>
                <TableTop align="center">#</TableTop>
                <TableTop align="center">Indikator Kinerja</TableTop>
                <TableTop align="center">Satuan</TableTop>
                <TableTop align="center">Indikator Capaian</TableTop>
                <TableTop align="center">Aksi</TableTop>
              </RowTop>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCells>{"(1)"}</TableCells>
                <TableCells>{"(2)"}</TableCells>
                <TableCells>{"(3)"}</TableCells>
                <TableCells>{"(4)"}</TableCells>
                <TableCells>{"(5)"}</TableCells>
              </TableRow>
              {renderCapaian(capaian)}
              <TableRow>
                <TableCells colSpan={8}>{""}</TableCells>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <DialogCapaian
        open={open}
        kegiatan={selectIndikator.kegiatan}
        handleClose={handleClose}
        submitCapaian={submitCapaian}
        handleChange={handleChangeCapaian}
      />
    </>
  );
};

export default CapaianKinerja;
