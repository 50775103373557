import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import qs from 'qs';
import { authToken } from "./user";

export const getSatuan = (navigate) => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}master/get_satuan`,{
            headers:{
                Authorization:'Bearer '+localStorage.getItem('token')
            }
        });

        const authError = authToken(res);
        if (authError) {
            return authError;
        }

        return dispatch(get_satuan(res.data));
        
    } catch (error) {
       //console.log(error); 
    }
}

export const createSatuan = (data) => async(dispatch)=>{
    try {
        const res = await axios.post(`${process.env.REACT_APP_URL}master/create_satuan`,qs.stringify(data),{
            headers:{
                Authorization:'Bearer '+localStorage.getItem('token')
            }
        });
        
        const authError = authToken(res);
        if (authError) {
            return authError;
        }

        return dispatch(getSatuan(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const deleteSatuan = (data) => async(dispatch)=>{
    try {
        const res = await axios.post(`${process.env.REACT_APP_URL}master/delete_satuan`,qs.stringify(data));
        return dispatch(getSatuan(res.data));
    } catch (error) {
        console.log(error);
    }
}

const Satuan = createSlice({
    name:'satuan',
    initialState:({satuan:[]}),
    reducers:{
        get_satuan(state, action){
            state.satuan = action.payload;
        },
        // get_userId(state, action){
        //     state.userId = action.payload;
        // },
        // delete_user(state,action){
        //     //console.log(action.payload);
        //     const user = state.user.filter(index => parseInt(index.id_user) !== parseInt(action.payload));
        //     state.user = user;
        // }
    }
});

const {get_satuan} = Satuan.actions;

export default Satuan; 