import { Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleTahunNew } from '../store/user';

const Settings = () => {

    const dispatch = useDispatch();

    const [selectedYear, setSelectedYear] = useState('');

    const tahun = useSelector(state=>state.User.tahun);

    useEffect(()=>{
      setSelectedYear(tahun);
    },[tahun]);

    const handleChange = (event) => {
      const selectedTahun = event.target.value;
      dispatch(handleTahunNew(selectedTahun));

    };
    
    const [daftarTahun,setDaftarTahun] = useState([]); 

    useEffect(()=>{
        const tahunSaatIni  = new Date().getFullYear();
        const tahunReverse = new Date().getFullYear() - 5;
        const newDaftarTahun = [];
        for (let tahunNow=tahunSaatIni; tahunNow >= tahunReverse; tahunNow--) {
            newDaftarTahun.push(tahunNow);
        }
        setDaftarTahun(newDaftarTahun);
    },[]);

  return (
    <>
        <Paper sx={{ width: '100%', overflow: 'hidden', p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
                <Typography sx={{m:2,fontSize:20}}> SETTINGS APP </Typography>
                </Grid>
            </Grid>
            <Divider />
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Tahun SPM</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedYear}
                label="Age"
                onChange={handleChange}
                >
                {daftarTahun.map((item)=>(
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
                </Select>
            </FormControl>
        </Paper>
    </>
  )
}

export default Settings