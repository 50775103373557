import React,{lazy,Suspense, useState} from 'react'
import {Routes,Route, Navigate, Outlet} from 'react-router-dom' 
// import Home from './pages/Home';
import Login from './pages/Login';
import Coba from './pages/Coba';
import DataUser from './pages/DataUser';
import Satuan from './pages/masters/Satuan';
import Pendanaan from './pages/masters/Pendanaan';
import IndikatorKinerja from './pages/masters/IndikatorKinerja';
import KegiatanAnggaran from './pages/Manage/KegiatanAnggaran';
import CapaianKinerja from './pages/masters/CapaianKinerja';
import InputCapaian from './pages/Manage/InputCapaian';
import Bidang from './pages/masters/Bidang';
import UserData from './pages/User/UserData';
import Settings from './pages/Settings';
import { useEffect } from 'react';
import CapaianReport from './pages/Report/CapaianReport';
import KegAnggaranReport from './pages/Report/KegAnggaranReport';
import Indikator from './pages/masters/Indikator';

const Home = lazy(() => import('./pages/Home'));

const Routernav = ({isLoggedIn,tahunNow}) => {
    const [keydata,setKeyData] = useState({hak_akses:'',kode_bidang:''});

    useEffect(()=>{
      const newHakAkses = localStorage.getItem('hak_akses');
      const newKodeBidang = localStorage.getItem('kode_bidang');

      if(newHakAkses && newKodeBidang ){
        setKeyData({...keydata,hak_akses:newHakAkses,kode_bidang:newKodeBidang});
      }
      
    },[keydata]);


  return (

        <Routes>
            <Route path="/" element={ isLoggedIn  ? <Suspense fallback={<div>Loading...</div>}><Home /></Suspense> : <Navigate to="/Login" />} />
            {/* <Route path="/Home" element={isLoggedIn  ? <Suspense fallback={<div>Loading...</div>}><Home /></Suspense> : <Navigate to="/Login" />} /> */}
            <Route path="/Login" element={isLoggedIn ? <Home /> :  <Login />} />
            <Route path="/coba" element={isLoggedIn ? <Coba /> :  <Login />} />
            <Route path="/coba/:id_user" element={isLoggedIn ? <Coba /> :  <Login />} />
            <Route path="/data_user" element={isLoggedIn ? <DataUser /> :  <Login />} />
            <Route path="Master" element={<Outlet />}>
              <Route path='satuan' element={<Satuan />} ></Route>
              <Route path='pendanaan' element={<Pendanaan />} ></Route>
              <Route path='indikator_kinerja' element={<IndikatorKinerja tahunNow={tahunNow} />} ></Route>
              <Route path='indikator' element={<Indikator />} ></Route>
              <Route path='capaian_kinerja' element={<CapaianKinerja />} ></Route>
              <Route path='bidang' element={<Bidang />} ></Route>
            </Route>
            <Route path="Manage" element={<Outlet />}>
              <Route path='kegiatan_anggaran' element={<KegiatanAnggaran />} ></Route>
              <Route path='input_capaian' element={<InputCapaian />} ></Route>
            </Route>
            <Route path='User'element={<Outlet />} >
              <Route path='user_data' element={<UserData />}></Route>
            </Route>
            <Route path="Report" element={<Outlet />}>
              <Route path="capaian_report" element={<CapaianReport />} />
              <Route path="keg_anggaran_report" element={<KegAnggaranReport />} />
            </Route>
            <Route path="/Settings" element={isLoggedIn ? <Settings /> :  <Login />} />
        </Routes>
  )
}

export default Routernav;