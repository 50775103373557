import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material'
import React from 'react'

const DialogCapaian = ({open,handleClose,kegiatan,submitCapaian,handleChange}) => {

    const setHandleChange = (e) =>{
        const newValue = e.target.value;
        handleChange(newValue);
    }

  return (
    <>
    {/* ====================== dialog ============================== */}
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Set Dasar Capaian </DialogTitle>
            <DialogContent>
              
              <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography>
                        Kegiatan : {kegiatan}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <TextField
                        autoFocus
                        label="Input Jumlah Target Capaian"
                        type="text"
                        fullWidth
                        variant="standard"
                        // value={realisasiInput.pelaksanaan}
                        // onChange={(e)=>{setRealisasiInput({...realisasiInput,pelaksanaan:formatRupiah(e.target.value)})}}
                        onChange={setHandleChange}
                        sx={{marginBottom:'20px'}}
                      />
                  </Grid>
              </Grid>
            </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={submitCapaian}>Send</Button>
          </DialogActions>
        </Dialog>
    </>
  )
}

export default DialogCapaian