import { TableCell, TableRow, styled } from "@mui/material";


export const TableTop = styled(TableCell)({
    border: "1px solid black",
    color:'#ffffff',
    fontWeight:'Bold',
    fontSize:'20px',
    alignContent:'center'
  });
  
export const RowTop = styled(TableRow)({
        backgroundColor:'#195BF1'
})

// export const AppBars = styled(AppBar)({
//     background: 'linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)',
//     // Anda bisa menambahkan properti CSS lainnya di sini jika diperlukan
//   });