import { Button ,Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { rupiah } from '../../function/FormatRupiah';
import { useDispatch, useSelector } from 'react-redux';
import {  Print } from '@mui/icons-material';
import { getIndikatorKinerja } from '../../store/manage';
import { getSelectPendanaan } from '../../store/master';
import { useReactToPrint } from 'react-to-print';
import '../../assets/style.css';

const TableCells = styled(TableCell)({
  border: "1px solid black",
});


const ButtonReport = styled(Button)(({theme})=>({
  borderRadius: '10%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  margin:'10px 10px',
  cursor:'pointer'
}));


const KegAnggaranReport = () => {
  //  const [sumberDana,setSumberDana] = useState([]);
   const [dataPagu,setDataPagu] = useState([]);
   const [triwulan,setTriwulan] = useState({triwulanNow:'',tahun:0});

   const kode_bidang = localStorage.getItem('kode_bidang') !== null ? localStorage.getItem('kode_bidang') : '';
   
   const dispatch = useDispatch();

  const pendanaanSelected = useSelector(state => state.Master.selectPendanaan);

  const tahun = useSelector(state=>state.User.tahun);

  // ==== set pendanaan dari database =======
  useEffect(()=>{
    dispatch(getSelectPendanaan(tahun)); 
},[dispatch,tahun]);


//======== set Triwulan data ============
const getYearAndMonth = () =>{
  const dateNow =  new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' }).slice(0,10);
  const [bulan,tahun] = dateNow.split('/');
  return {
    bulan : parseInt(bulan,10),
    tahun : parseInt(tahun,10)
  }
}

// ================ mencari data triwulan ===========================
useEffect(()=>{
  const {bulan,tahun} = getYearAndMonth();
  const dataTriwulan = Math.ceil(bulan / 3);
  setTriwulan({triwulanNow:dataTriwulan,tahun:tahun});
},[]);


const changeTriwulan = async(e)=>{
  await setTriwulan({...triwulan,triwulanNow:e.target.value});
}

useEffect(() => {
  if (pendanaanSelected && pendanaanSelected.length > 0) {
    dispatch(getIndikatorKinerja({ id_pendanaan: pendanaanSelected[0]?.id_pendanaan, kode_bidang: kode_bidang, triwulan: triwulan?.triwulanNow }));
  } else {
    dispatch(getSelectPendanaan(tahun));
  }
}, [dispatch,kode_bidang,triwulan, pendanaanSelected, tahun]);

// ==================================================================
  
   const getIndikator = useSelector(state=>state.Manage.indikator);
   const [callCount, setCallCount] = useState(0);

    useEffect(() => {
      if (dataPagu.length === 0 && callCount < 10) {
        try {
           //const sumberDanaLocalStorage = JSON.parse(localStorage.getItem('sumberDana'));
           //===== panggil data============
           setCallCount(prevCount => prevCount + 1);
             //dispatch(getIndikatorKinerja({ id_pendanaan: sumberDanaLocalStorage.id_pendanaan }));
             pendanaanSelected?.length > 0 && dispatch(getIndikatorKinerja({ id_pendanaan: pendanaanSelected[0]?.id_pendanaan,kode_bidang:kode_bidang,triwulan:triwulan.triwulanNow }));
             setDataPagu(getIndikator);
              if(getIndikator.length > 0){
                setCallCount(10);
              }
        } catch (error) {
           console.error("Error dalam request:", error);
        }
     }
  }, [dataPagu,callCount,dispatch,getIndikator,kode_bidang,pendanaanSelected,triwulan]);
   


  //=================Menu Item ============================

    useEffect(() => {
     // console.log('dataPagu sebelum diperbarui:', dataPagu);
      setDataPagu([]);
      // console.log('dataPagu setelah diperbarui:', dataPagu);
      setDataPagu(getIndikator);
      
    }, [getIndikator]);



   const renderIndikator = (data) =>{
    return(
          data.map((row,index) => (
            <React.Fragment key={row.id_indikator}>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCells align='left' >{row.program !== '' && index+1}</TableCells>
                <TableCells align='left' >{row.program}</TableCells>
                <TableCells align='left' >{row.kegiatan !== '' && index+1 +".  "+ row.kegiatan}</TableCells>
                <TableCells align='left' >{row.sub_kegiatan !== '' && index+1 +".  "+ row.sub_kegiatan}</TableCells>
                <TableCells align='left' >{row.satuan}</TableCells>
                <TableCells align='left' >{rupiah(row.perencanaan)}</TableCells>
                <TableCells align='left' >{rupiah(row.realisasi)}</TableCells>
                <TableCells align='left' >{row.persentase+'%'}</TableCells>
              </TableRow>
              {renderIndikator(row.child)}
            </React.Fragment>
        ))
    )
   }

  const tableRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  return (
    <>
      <Typography variant="h4" sx={{mb:4}} color="initial">
        Report Kegiatan Anggaran
      </Typography>
      <Paper ref={tableRef} sx={{width: '100%', overflow:'hidden'}}>
      <Grid container spacing={2}>
          <Grid item xs={3} display="flex" alignItems="center" justifyContent="flex-start">
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                <InputLabel id="demo-simple-select-label">Triwulan</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={triwulan.triwulanNow}
                  label="Triwulan"
                  onChange={changeTriwulan}
                >
                  <MenuItem value={1}>Triwulan 1</MenuItem>
                  <MenuItem value={2}>Triwulan 2</MenuItem>
                  <MenuItem value={3}>Triwulan 3</MenuItem>
                  <MenuItem value={4}>Triwulan 4</MenuItem>
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h5">
                Report Kegiatan Anggaran {tahun}
              </Typography>
          </Grid>
          <Grid item xs={3} id='print-button' display="flex" justifyContent="flex-end">
            <ButtonReport
                variant="outlined"
                onClick={handlePrint}
                startIcon={<Print />}
              >
                Print
              </ButtonReport>
          </Grid>
        </Grid>
        <Divider />
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}  aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCells align="left">#</TableCells>
                    <TableCells align="left">Program</TableCells>
                    <TableCells align="left">Kegiatan</TableCells>
                    <TableCells align="left">Sub Kegiatan</TableCells>
                    <TableCells align="left">Satuan</TableCells>
                    <TableCells align="left">Perencanaan</TableCells>
                    <TableCells align="left">Realisasi Anggaran</TableCells>
                    <TableCells align="left">Persentase</TableCells>
                </TableRow>
                </TableHead>
                <TableBody>
                  {renderIndikator(dataPagu)}

                  <TableRow>
                    <TableCells colSpan={9}></TableCells>
                  </TableRow>
                  
                </TableBody>
            </Table>
            </TableContainer>
      </Paper>
    </>
  )
}

export default KegAnggaranReport