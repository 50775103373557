import React,{useState,useEffect} from 'react'
import { Box, Button, Card, CardContent, CardHeader,  Divider, Grid, TextField } from '@mui/material'
import { createUser,getUserId,updateUser } from '../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';


const Coba = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [user,setUser] = useState({username:'',password:'',nama:''});
  const userId = useSelector(state=>state.User.userId);
  const id_user = params.id_user;
  const navigate = useNavigate();

  useEffect(()=>{
    if(id_user){
       dispatch(getUserId(id_user));
    }
  },[dispatch,id_user]);

  useEffect(() => {
    if (parseInt(userId.length) !== 0) {
      //setUser({ ...userId });
      // const newUser = {username:userId.username,password:userId.password,nama:userId.nama,id_user:userId.id_user};
      // setUser((prev)=>({...prev,...newUser}));
      setUser({username:userId.username,password:userId.password,nama:userId.nama,id_user:userId.id_user});
    }
  }, [userId]);

  const submitData = async(e) =>{
    e.preventDefault();
    await dispatch(createUser(user));
  }

  const updateData = async(e) =>{
    e.preventDefault();
    //console.log(user);
    await dispatch(updateUser(user));
    await navigate('/data_user');
  }

  return (
    <Grid container spacing={2} >
        <Grid item xs={12} md={12}>
            <Card>
                <CardHeader
                title="Add Layanan"
                subheader=""
                />
            <Divider></Divider>
            <CardContent>
            <form onSubmit={id_user ? updateData  : submitData}>
                <Grid container spacing={2}>
                    
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth label='Username' value={user.username} 
                    onChange={(e)=>{setUser({...user,username:e.target.value})}}
                    // onChange={(value) => setUser((prev) => ({
                    //     ...prev,
                    //     username: value
                    //   }))} 
                   placeholder='Username' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth label='Password' value={user.password} onChange={(e)=>{setUser({...user,password:e.target.value})}} placeholder='Password' />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth label='Nama' value={user.nama} onChange={(e)=>{setUser({...user,nama:e.target.value})}} placeholder='Nama' />
                </Grid>
                <Grid item xs={12}>
                            <Box
                            sx={{
                                gap: 5,
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                            >
                            <Button type='submit' variant='contained' size='large'>
                               {id_user ? "Edit User"  : "Add User"}
                              {/* {loading ? <CircularProgress /> : ' Add Layanan'} */}
                            </Button>
                            </Box>
                        </Grid>
                </Grid>
            </form>
            
                </CardContent>
                </Card>
            </Grid>
    </Grid>
  )
}

export default Coba