import React,{useEffect, useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from '@mui/material';
import {Delete} from '@mui/icons-material';
import { createBidang, deleteBidang, getBidang } from '../../store/master';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../store/user';

const StyledButton = styled(Button)({
  backgroundColor:'#4875EE',
  color:'#ffffff',
  margin:'10px',
})

const Satuan = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [bidang,setBidang] = useState({nama_bidang:'',kode_bidang:''});

    function createData(id_bidang,nama_bidang,kode_bidang) {
        return { id_bidang,nama_bidang,kode_bidang };
      }
      
      const rowData = [];

      useEffect(()=>{
        dispatch(getBidang())
        .then((res)=>{
          //console.log(res)
          if(res?.status === 'error'){
              dispatch(logoutUser());
              navigate('/login');
          }
        });
      },[dispatch,navigate]);
      
      const bidangData = useSelector(state => state.Master.bidang);

      bidangData.forEach(row=> {
            rowData.push(createData(row.id_bidang,row.nama_bidang,row.kode_bidang));
      });

      //===================Open Dialog ===============================
      const [open, setOpen] = useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const submitHandler = async(e) =>{
         e.preventDefault();
         await dispatch(createBidang(bidang));
         await handleClose();
         await dispatch(getBidang());
      }

      const deleteHandler = async(id_bidang) =>{
        if(window.confirm('Anda Yakin ?')){
          await dispatch(deleteBidang({id_bidang:id_bidang}));
        }
      }

  return (
    <>
    <Typography variant="h4" sx={{mb:4}} color="initial">
      
    </Typography>
    <Paper sx={{width: '100%', overflow:'hidden'}}>
    <Grid container spacing={2}>
        <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
          <Typography sx={{m:2,fontSize:20}}>Bidang & UPTD </Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <StyledButton 
              onClick={handleClickOpen}
            >  Add Bidang </StyledButton>
        </Grid>
      </Grid>
      <Divider />
      <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
              <TableRow>
                  <TableCell align="left">#</TableCell>
                  <TableCell align="left">Bidang</TableCell>
                  <TableCell align="left">kode_bidang</TableCell>
                  <TableCell align="left">Opsi</TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {rowData.map((row,index) => (
                  <TableRow
                  key={row.id_bidang}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                  <TableCell align='left' >{index+1}</TableCell>
                  <TableCell align='left' >{row.nama_bidang}</TableCell>
                  <TableCell align='left' >{row.kode_bidang}</TableCell>
                  <TableCell align='left' ><Delete sx={{ color: 'red' }} onClick={(e)=>deleteHandler(row.id_bidang)} /></TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
    </Paper>
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <DialogTitle>Tambah Bidang</DialogTitle>
        <DialogContent>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <TextField
                        autoFocus
                        label="Nama Bidang"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={bidang.nama_bidang}
                        onChange={(e)=>{setBidang({...bidang,nama_bidang:e.target.value})}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                            autoFocus
                            label="Kode Bidang"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={bidang.kode_bidang}
                            onChange={(e)=>{setBidang({...bidang,kode_bidang:e.target.value})}}
                        />
                </Grid>
            </Grid>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={submitHandler}>Send</Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default Satuan