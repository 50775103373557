import { LibraryAdd, MoreVert } from "@mui/icons-material";
import { IconButton, ListItemIcon, Menu, MenuItem, MenuList } from "@mui/material";
import React from "react";

const ButtonMenuCapaian = ({id_indikator,id_pendanaan,jenis,kegiatan,anchorEl,handleClick,handleClickOpen,handleClose2}) => {
  return (
    <>
      <IconButton
        id={`menu-button${id_indikator}`}
        data-id_indikator={id_indikator}
        data-id_pendanaan={id_pendanaan}
        data-jenis = {jenis}
        data-kegiatan = {kegiatan}
        aria-controls={
          Boolean(anchorEl) ? `menu-button${id_indikator}` : undefined
        }
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id={`menu-drop${id_indikator}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose2}
        MenuListProps={{ "aria-labelledby": `menu-button${id_indikator}` }}
      >
        <MenuList sx={{ width: 170, maxWidth: "100%" }}>
          <MenuItem onClick={handleClickOpen} disableRipple>
            <ListItemIcon>
              <LibraryAdd />
            </ListItemIcon>
            SET CAPAIAN
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default ButtonMenuCapaian;
