import { Cancel, LibraryAdd, ModeEditOutline, MoreVert } from '@mui/icons-material';
import { IconButton, ListItemIcon, Menu, MenuItem, MenuList } from '@mui/material';
import React, { useEffect } from 'react'
import { useState } from 'react';

const ButtonIndikator = ({id_indikator,kode,anchorEl,handleClick,handleClose2,handleClickOpen,handleClickRevisi,indikatorData,idPendanaan,openHandlePembatalan,row}) => {
  return (
    <>
      <IconButton
        id={`menu-button${id_indikator}`}
        data-id_pendanaan={id_indikator}
        data-indikator={
          kode === "" //ambil data dari program
            ? "program"
            : kode === "kegiatan" //ambil data dari row
            ? "sub_kegiatan"
            : kode === "sub_kegiatan"
            ? "sub_sub_kegiatan"
            : ('')
        }
        aria-controls={
          Boolean(anchorEl) ? `menu-button${id_indikator}` : undefined
        }
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        onClick={handleClick} //============= dropdown click ========================
      >
        <MoreVert />
      </IconButton>
      <Menu
        id={`menu-drop${id_indikator}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose2}
        MenuListProps={{
          "aria-labelledby": `menu-button${id_indikator}`,
        }}
      >
        <MenuList sx={{ width: 170, maxWidth: "100%" }}>
          {indikatorData !== "sub_sub_kegiatan" && (
              <MenuItem
              onClick={() => handleClickOpen(indikatorData, idPendanaan)}
              disableRipple
            >
              <ListItemIcon>
                <LibraryAdd />
              </ListItemIcon>
              Add Kegiatan
            </MenuItem>
          )}
          <MenuItem
            onClick={() => handleClickRevisi()}
            disableRipple
          >
            <ListItemIcon>
              <ModeEditOutline />
            </ListItemIcon>
            Revisi
          </MenuItem>
          {indikatorData !== "program" && (
          <MenuItem
            onClick={() => openHandlePembatalan()}
            disableRipple
          >
            <ListItemIcon>
              <Cancel />
            </ListItemIcon>
            Pembatalan
          </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
}

export default ButtonIndikator