import {
  Alert,
  Collapse,
  Divider,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RowTop, TableTop } from "../../assets/styled";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createIndikator, createSelectPendanaan, getBidang, getIndikatorId, getIndikatorParent, getParent, getSelectPendanaan, setSubmitRevisi, submitPembatalanIndikator, updateIndikatorId } from "../../store/master";
import { logoutUser } from "../../store/user";
import { formatRupiah, rupiah } from "../../function/FormatRupiah";
import { Close, LibraryAdd, MoreVert } from "@mui/icons-material";
import ButtonIndikator from "../../component/ButtonIndikator";
import IndikatorKinerja from "./IndikatorKinerja";
import DialogIndikator from "../../component/DialogIndikator";
import { getSatuan } from "../../store/satuan";
import DialogIndikatorRevisi from "../../component/DialogIndikatorRevisi";
import { AES, enc } from "crypto-js";
import DialogPembatalanIndikator from "../../component/DialogPembatalanIndikator";

const TableCells = styled(TableCell)({
  border: "1px solid black",
});

const Indikator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // =============  open dialog Add Indikator ===============================
  const [open, setOpen] = useState(false);
  const [newIndikator, setNewIndikator] = useState("");

  const handleClickOpen = async (IndikatorPagu, idParent) => {
  //  console.log(IndikatorPagu);
    let totalPagu = 0;
    if (IndikatorPagu === "program") {
      await setIndikator({ ...indikator, parent: 0, kode: IndikatorPagu });
      await parentIndikator?.forEach((row) => {
        totalPagu += parseInt(row.perencanaan);
      });
      totalPagu = (await parseInt(selectDana.pagu)) - totalPagu;
    } else
     if (IndikatorPagu === "kegiatan") {
      await setIndikator({
        ...indikator,
        parent: idParent,
        kode: IndikatorPagu,
      });
      const paguNow = await dataIndikatorNew.find(
        (row) => parseInt(row.id_indikator) === parseInt(idParent)
      );
      for (const data of dataIndikatorNew) {
        const cariPagu = await data.child.filter(
          (row) => parseInt(row.parent) === parseInt(idParent)
        );
        if (cariPagu.length !== 0) {
          await cariPagu.forEach((row) => {
            totalPagu += parseInt(row.perencanaan);
          });
        }
      }
      totalPagu = (await parseInt(paguNow.perencanaan)) - totalPagu;
    } else if (IndikatorPagu === "sub_kegiatan") {
      let dataPagu = [];
      let paguNow;
      await setIndikator({
        ...indikator,
        parent: idParent,
        kode: IndikatorPagu,
      });

      const rekursifData = async (dataIndikatorNew, idParent) => {
        const dataPagu = [];
        for (const row of dataIndikatorNew) {
          const findIndikator = await row.child.filter(
            (row) => parseInt(row.parent) === parseInt(idParent)
          );
          if (findIndikator.length > 0) {
            dataPagu.push(...findIndikator);
          } else {
            const recursiveResult = await rekursifData(row.child, idParent);
            dataPagu.push(...recursiveResult);
            // rekursifData(row.child,idParent);
          }
        }
        return dataPagu;
      };
      const result = await rekursifData(dataIndikatorNew, idParent);
      dataPagu.push(...result);

      for (const data of dataIndikatorNew) {
        // ========= find pagu yang dipilih agar mendapatkan nilai perencanaan ==============================================
        let findPaguSelect = await data.child.find(
          (row) => parseInt(row.id_indikator) === parseInt(idParent)
        );
        if (findPaguSelect !== undefined) {
          paguNow = findPaguSelect;
        }
      }

      if (dataPagu.length > 0) {
        for (const row of dataPagu) {
          totalPagu += parseInt(row.perencanaan);
        }
      }
      totalPagu = (await parseInt(paguNow.perencanaan)) - totalPagu;
    }

    totalPagu = totalPagu;
    setSisaPagu(totalPagu);
    setNewIndikator(IndikatorPagu);
    setOpen(true);
    handleClose2();
  };


  // =============  open dialog Revisi Pagu ===============================

  const [openRevisi,setOpenRevisi] = useState(false);
  const [indikatorId,setIndikatorId] = useState({});
  
  const handleClickRevisi = async () =>{

    const encryptedData = AES.encrypt(idPendanaan.toString(), "webspm").toString();
    const base64EncryptedData = enc.Base64.stringify(enc.Utf8.parse(encryptedData));
     
    // ======= idpendanaan atau id_indikator ===========
      await dispatch(getIndikatorId(base64EncryptedData));
      setOpenRevisi(true);
      handleClose2();
    }

  let dataIndikatorId = useSelector((state)=>state.Master.indikatorId);

  const updateIndikatorById = async(newDataIndikatorId) =>{
      // dispatch(updateIndikatorId(newDataIndikatorId));
      await setIndikatorId(newDataIndikatorId);
  }

  const submitRevisi = async() =>{
      //console.log(indikatorId);
      await dispatch(setSubmitRevisi(indikatorId));
      await selectDanaHandler();
      handleCloseRevisi();
  } 

    useEffect(() => {
      setIndikatorId(dataIndikatorId)
     }, [dataIndikatorId]);

  const handleCloseRevisi = () => {
    setIndikatorId({
      program: "",
      kegiatan: "",
      sub_kegiatan: "",
      satuan: "",
      perencanaan: "",
      parent: "",
      kode: "",
      kode_bidang: "",
    });
    setOpenRevisi(false);
  };

  // ========================================================================

  const handleClose = async () => {
    await setOpen(false);
    await setIndikator({
      ...indikator,
      program: "",
      kegiatan: "",
      sub_kegiatan: "",
      satuan: "",
      perencanaan: "",
      parent: "",
      kode: "",
      kode_bidang: "",
    });
  };
  //============================================================

  // =============  open dialog Pembatalan =====================

      // ======== snack bar pembatalan ==========
      const [alert, setAlert] = useState({
        open: false,
        severity: 'success',
        text:''
      });

      let alertValue = useSelector((state) => state.Master.alert);
  

    const [openPembatalan,setOpenPembatalan] = useState(false);

    const openHandlePembatalan = async() =>{
        setOpenPembatalan(true);
        handleClose2();
    }

    const handleClosePembatalan = async() =>{
      await setOpenPembatalan(false);
    }

    const submitPembatalan = async()=>{

     await dispatch(submitPembatalanIndikator({id_indikator:idPendanaan}));
     if(alertValue){
        await setAlert({...alert,open:true,text:'Pembatalan Berhasil Di Proses'});
     }else if(alertValue !== true){
        await setAlert({...alert,open:true,severity: 'error',text:'Pembatalan Tidak Bisa Di Proses, Silahkan Cek data indikator yang sudah terinput !'});
     }
    

     await selectDanaHandler();
     await setOpenPembatalan(false);
    }

  // ===========================================================

  const [indikator, setIndikator] = useState({
    program: "",
    kegiatan: "",
    sub_kegiatan: "",
    satuan: "",
    perencanaan: "",
    kode_bidang: "",
    parent: "",
    id_pendanaan: "",
    kode: "",
  });

  // === indikator pendanaan 2 buah yang di select =======
  const [pendanaan, setPendanaan] = useState({
    id_pendanaan: "",
    id_sumber_dana: "",
  });

  // data child setelah id_pendanaan didapatkan karena select pertama dan kedua saling berkaitan
  const [sumberPendanaan, setSumberPendanaan] = useState([]);

  // data sumber dana setelah tombol select di klik
  const [selectDana, setSelectDana] = useState([]);

  //untuk mengetahui sisa pagu pada saat dialog muncul
  const [sisaPagu, setSisaPagu] = useState("");

  //select Pendanaan yang diambil dari database
  const [dataIndikatorNew, setDataIndikatorNew] = useState([]);

  const tahunSelect = useSelector((state) => state.User.tahun);

  useEffect(() => {
    dispatch(getParent(tahunSelect)).then((res) => {
      // ====== hasil authentikasi token =========
      if (res.status === "error") {
        dispatch(logoutUser());
        navigate("/login");
      }
      //============================================
    });

    dispatch(getSelectPendanaan(tahunSelect));
  }, [pendanaan, tahunSelect, dispatch, navigate]);

  const dataIndikator = useSelector((state) => state.Master.pendanaan);

  const pendanaanSelected = useSelector(
    (state) => state.Master.selectPendanaan
  );

  const parentIndikator = useSelector((state) => state.Master.indikator);

  useEffect(() => {
    setDataIndikatorNew(parentIndikator);
  }, [parentIndikator]);

  // ==== set pendanaan dari database =======
  useEffect(() => {
    if (pendanaanSelected?.length > 0) {
      setIndikator({
        ...indikator,
        id_pendanaan: pendanaanSelected[0].id_pendanaan,
      });
      setSelectDana(...pendanaanSelected);
      dispatch(
        getIndikatorParent({
          id_pendanaan: pendanaanSelected[0].id_pendanaan,
          tahun: tahunSelect,
        })
      );
    }
  }, [pendanaanSelected]);

  const selectDanaHandler = async() =>{
    const selected = await sumberPendanaan.find(row => parseInt(row.id_pendanaan) === parseInt(pendanaan.id_sumber_dana));
    await setSelectDana({...selected});
    await setIndikator({...indikator,id_pendanaan:pendanaan.id_sumber_dana});
    await dispatch(getIndikatorParent({id_pendanaan:pendanaan.id_sumber_dana,tahun:tahunSelect}));
    await dispatch(createSelectPendanaan(selected));
}

  const rupiahChange = (val) => {
    let rupiahNew = rupiah(val);
    return rupiahNew;
  };

  //=================Menu Item ============================
  const [anchorEl, setAnchorEl] = useState(null);
  const [idPendanaan, setIdPendanaan] = useState("");
  const [indikatorData, setIndikatorData] = useState("");
  // const open = Boolean(anchorEl);

  const handleClick = (event) => {
    //console.log(event.currentTarget.dataset);
    let { id_pendanaan, indikator } = event.currentTarget.dataset;
    setIdPendanaan(id_pendanaan || "");
    if(indikator===''){indikator = 'kegiatan'}
    setIndikatorData(indikator);
    setAnchorEl(event.currentTarget);
    anchorEl === null ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
  };

  const handleClose2 = async () => {
    await setAnchorEl(null);
  };

  useEffect(()=>{
    //console.log(indikator);
  },[indikator]);

  useEffect(()=>{
    //console.log(indikatorData);
  },[indikatorData]);

  //==== select Bidang =============

  useEffect(() => {
    dispatch(getBidang());
  }, [dispatch]);

  const bidang = useSelector((state) => state.Master.bidang);

  //===================================

  const renderParent = (dataIndikatorNew) => {
    if (dataIndikatorNew && Array.isArray(dataIndikatorNew)) {
      return dataIndikatorNew.map((row, index) => (
        <React.Fragment key={row.id_indikator}>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCells align="left">
              {row.program !== "" && index + 1}
            </TableCells>
            <TableCells
              align="left"
              colSpan={row.program !== "" ? 3 : 1}
              style={{ display: row.program !== "" ? "table-cell" : "none" }}
            >
              {row.program !== "" && row.program}
            </TableCells>
            <TableCells
              align="left"
              colSpan={row.kegiatan !== "" ? 3 : 1}
              style={{
                display: row.kegiatan !== "" ? "table-cell" : "none",
                paddingLeft: "15vw",
              }}
            >
              {row.kegiatan !== "" && index + 1 + ".  " + row.kegiatan}
            </TableCells>
            <TableCells
              align="left"
              colSpan={row.sub_kegiatan !== "" ? 3 : 1}
              style={{
                display: row.sub_kegiatan !== "" ? "table-cell" : "none",
                paddingLeft: "30vw",
              }}
            >
              {row.sub_kegiatan !== "" && index + 1 + ".  " + row.sub_kegiatan}
            </TableCells>
            <TableCells align="left">
              {row.sub_kegiatan !== "" && row.satuan}
            </TableCells>
            <TableCells align="left">
              {rupiahChange(row.perencanaan)}
            </TableCells>
            {/* <TableCells align='left' ><Delete sx={{ color: 'red' }} onClick={(e)=>deleteHandler(row.id_satuan)} /></TableCells> */}
            <TableCells align="left">
                <ButtonIndikator
                    id_indikator={row.id_indikator}
                    kode={row.kode}
                    anchorEl={anchorEl}
                    handleClick={handleClick}
                    handleClose2={handleClose2}
                    handleClickOpen={handleClickOpen}
                    handleClickRevisi={handleClickRevisi}
                    indikatorData={indikatorData}
                    idPendanaan={idPendanaan}
                    openHandlePembatalan={openHandlePembatalan}
                  />
            </TableCells>
          </TableRow>
          {renderParent(row.child)}
        </React.Fragment>
      ));
    } else {
      return null;
    }
  };

  const indikatorHandle = async (value) => {
        await setIndikator(value);
  };

  const indikatorHandleBidang = async (value) => {
    await setIndikator(value);
  };
  
  const handleInputChange = async (value) => {
    const formattedValue = await formatRupiah(value);
    setIndikator({ ...indikator, perencanaan: formattedValue });
  };

  const submitProgram = async () => {
    await dispatch(createIndikator(indikator));
    await setIndikator({
      ...indikator,
      program: "",
      kegiatan: "",
      sub_kegiatan: "",
      satuan: "",
      perencanaan: "",
      parent: "",
      kode: "",
      kode_bidang: "",
    });
    await selectDanaHandler();
    handleClose();
  };

  useEffect(()=>{

  },[indikator])

  // ================== Setting Satuan ==============================
  const [satuanSelect, setSatuanSelect] = useState("");

  useEffect(() => {
    dispatch(getSatuan());
  }, [dispatch]);

  const satuan = useSelector((state) => state.Satuan.satuan);

  const handleSatuan = async (event) => {
    await setSatuanSelect(event.target.value);
    setIndikator({ ...indikator, satuan: event.target.value });
  };

  //====================================================================

  useEffect(()=>{

  },[bidang]);

  return (
    <>
      <Typography variant="h4" sx={{ mb: 4 }} color="initial">
        Indikator
      </Typography>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography sx={{ m: 2, fontSize: 20 }}>
              Tabel Indikator Kinerja
            </Typography>
          </Grid>
        </Grid>
        {/* ================ Snack bar pembatalan ======================= */}
          <Collapse in={alert.open}>
            <Alert
              severity={alert.severity}
              action={
                <Icon
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlert(false);
                  }}
                >
                  <Close fontSize="inherit" />
                </Icon>
              }
              sx={{ mb: 2 }}
            >
              {alert.text}
            </Alert>
          </Collapse>
        {/* ================================================================ */}
        <Divider />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <RowTop>
                <TableTop align="left">#</TableTop>
                <TableTop align="left">Program</TableTop>
                <TableTop align="left">Kegiatan</TableTop>
                <TableTop align="left">Sub Kegiatan</TableTop>
                <TableTop align="left">Satuan</TableTop>
                <TableTop align="left">Perencanaan</TableTop>
                <TableTop align="left">Opsi</TableTop>
              </RowTop>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCells align="left">{"(1)"}</TableCells>
                <TableCells align="left">{"(2)"}</TableCells>
                <TableCells align="left">{"(3)"}</TableCells>
                <TableCells align="left">{"(4)"}</TableCells>
                <TableCells align="left">{"(5)"}</TableCells>
                <TableCells align="left">{"(6)"}</TableCells>
                <TableCells align="left">{"(7)"}</TableCells>
              </TableRow>
              {!isNaN(selectDana.pagu) ? (
                <TableRow>
                  <TableCells align="left"></TableCells>
                  <TableCells align="left" colSpan={4}>
                    {"Kalimantan Selatan"}
                  </TableCells>
                  <TableCells align="left">
                    {rupiahChange(selectDana.pagu)}
                  </TableCells>
                  <TableCells align="left">
                    <ButtonIndikator
                      id_indikator={selectDana.id_pendanaan}
                      kode={""}
                      anchorEl={anchorEl}
                      handleClick={handleClick}
                      handleClose2={handleClose2}
                      handleClickOpen={handleClickOpen}
                      indikatorData={indikatorData}
                      idPendanaan={idPendanaan}
                      openHandlePembatalan={openHandlePembatalan}
                    />
                  </TableCells>
                </TableRow>
              ) : (
                ""
              )}
              {renderParent(dataIndikatorNew)}
              <TableRow>
                <TableCells colSpan={7}>{""}</TableCells>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* =========================== Dialog Add Kegiatan ================================== */}
      <DialogIndikator
        open={open}
        handleClose={handleClose}
        rupiahChange={rupiahChange}
        sisaPagu={sisaPagu}
        indikatorHandle={indikatorHandle}
        indikatorHandleBidang={indikatorHandleBidang}
        handleInputChange={handleInputChange}
        newIndikator={newIndikator}
        indikator={indikator}
        submitProgram={submitProgram}
        satuan={satuan}
        handleSatuan={handleSatuan}
        satuanSelect={satuanSelect}
        bidang={bidang}
      />

      {/* =========================== Dialog Revisi Pagu ================================== */}

      <DialogIndikatorRevisi openRevisi={openRevisi} handleCloseRevisi={handleCloseRevisi} indikatorId={indikatorId} updateIndikatorById={updateIndikatorById} submitRevisi={submitRevisi} indikatorData ={indikatorData} selectDana={selectDana} />

      {/* =========================== Dialog Pembatalan Indikator ========================= */}

      <DialogPembatalanIndikator openPembatalan={openPembatalan} handleClosePembatalan={handleClosePembatalan} submitPembatalan={submitPembatalan} />

    </>
  );
};

export default Indikator;
