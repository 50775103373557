
import { configureStore } from "@reduxjs/toolkit";

import User from "./user";
import Satuan from './satuan';
import Master from "./master";
import Manage from "./manage";

const  store = configureStore({
    reducer:{
        User:User.reducer,
        Satuan:Satuan.reducer,
        Master:Master.reducer,
        Manage:Manage.reducer
    }
});

export default store;