import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, ListItemIcon, Menu, MenuItem, MenuList, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createIndikator, createSelectPendanaan, getBidang, getIndikatorParent, getParent, getSelectPendanaan } from '../../store/master';
import { formatRupiah, rupiah } from '../../function/FormatRupiah';
import { LibraryAdd, MoreVert } from '@mui/icons-material';
import { RowTop, TableTop } from '../../assets/styled';
import { logoutUser } from '../../store/user';
import { useNavigate } from 'react-router-dom';
import { getSatuan } from '../../store/satuan';
// const StyledButton = styled(Button)({
//     backgroundColor:'#4875EE',
//     color:'#ffffff',
//     margin:'10px',
//   });

const TableCells = styled(TableCell)({
    border: "1px solid black",
  });

const IndikatorKinerja = () => {

      const navigate = useNavigate();
      const dispatch = useDispatch();
      const [indikator,setIndikator] = useState({
                                                program:'',
                                                kegiatan:'',
                                                sub_kegiatan:'',
                                                satuan:'',
                                                perencanaan:'',
                                                kode_bidang:'',
                                                parent:'',
                                                id_pendanaan:'',
                                                kode:''
                                            });
    // === indikator pendanaan 2 buah yang di select =======
     const [pendanaan,setPendanaan] = useState({id_pendanaan:'',id_sumber_dana:''});

     // data child setelah id_pendanaan didapatkan karena select pertama dan kedua saling berkaitan
     const [sumberPendanaan,setSumberPendanaan] = useState([]);
     
     // data sumber dana setelah tombol select di klik 
     const [selectDana,setSelectDana] = useState([]);
     
     //untuk mengetahui sisa pagu pada saat dialog muncul
     const [sisaPagu,setSisaPagu] = useState('');


    //select Pendanaan yang diambil dari database
    // const [selectPendanaan,setSelectPendanaan] = useState([]);

     const [dataIndikatorNew,setDataIndikatorNew] = useState([]);

     const tahunSelect = useSelector(state=>state.User.tahun);

    useEffect(()=>{
       //console.log(tahunSelect)
        dispatch(getParent(tahunSelect))
        .then((res)=>{

            // ====== hasil authentikasi token =========
              if(res.status === 'error'){
                dispatch(logoutUser());
                navigate('/login');
            }
            //============================================

          });

        dispatch(getSelectPendanaan(tahunSelect));  
    },[pendanaan,tahunSelect,dispatch,navigate]);

    const rupiahChange = (val) =>{
        let rupiahNew = rupiah(val);
        return rupiahNew;
    }

    const dataIndikator = useSelector(state=>state.Master.pendanaan);

    const pendanaanSelected = useSelector(state => state.Master.selectPendanaan); 

    const tahun = localStorage.getItem('tahun');

    // ==== set pendanaan dari database =======
    useEffect(()=>{
        // const selected = sumberPendanaan.find(row => parseInt(row.id_pendanaan) === parseInt(pendanaanSelected[0].id_pendanaan));
        if(pendanaanSelected?.length > 0){
            setIndikator({...indikator,id_pendanaan:pendanaanSelected[0].id_pendanaan});
            setSelectDana(...pendanaanSelected);
            dispatch(getIndikatorParent({id_pendanaan:pendanaanSelected[0].id_pendanaan,tahun:tahun}));
            //dispatch(getIndikatorParent({id_pendanaan:pendanaan.id_sumber_dana,tahun:tahun}));
        }
    },[pendanaanSelected]);
    

    const pendanaanChange = async(e) =>{
        await setPendanaan({...pendanaan,id_pendanaan:e.target.value});
        const danaChild = await dataIndikator.find(row => parseInt(row.id_pendanaan) === parseInt(e.target.value));
        await setSumberPendanaan(danaChild?.child);
    }
    const sumberChange = async(e) =>{
       await setPendanaan({...pendanaan,id_sumber_dana:e.target.value});
    }

    const selectDanaHandler = async() =>{
        const selected = await sumberPendanaan.find(row => parseInt(row.id_pendanaan) === parseInt(pendanaan.id_sumber_dana));
        await setSelectDana({...selected});
        await setIndikator({...indikator,id_pendanaan:pendanaan.id_sumber_dana});
        await dispatch(getIndikatorParent({id_pendanaan:pendanaan.id_sumber_dana,tahun:tahun}));
        await dispatch(createSelectPendanaan(selected));
    }

    const parentIndikator = useSelector(state=>state.Master.indikator);

    useEffect(()=>{
        setDataIndikatorNew(parentIndikator);
    }, [parentIndikator]);


    const handleInputChange = (value) => {
        const formattedValue = formatRupiah(value);
        setIndikator({...indikator,perencanaan:formattedValue});
      }

      const submitProgram = async() =>{
           await dispatch(createIndikator(indikator));
           await setIndikator({...indikator,program:'',
           kegiatan:'',
           sub_kegiatan:'',
           satuan:'',
           perencanaan:'',
           parent:'',
           kode:'',
           kode_bidang:''
        });
        await selectDanaHandler();
           handleClose();
      }

      //===================Open Dialog ===============================
      const [open, setOpen] = useState(false);
      const [newIndikator,setNewIndikator] = useState('');

      const handleClickOpen = async(IndikatorPagu,idParent) => {
        //console.log(IndikatorPagu);
        let totalPagu = 0;
        if(IndikatorPagu === 'program'){
           await setIndikator({...indikator,parent:0,kode:IndikatorPagu})
           await parentIndikator?.forEach(row => {
                totalPagu += parseInt(row.perencanaan);
            });
            totalPagu = await parseInt(selectDana.pagu) - totalPagu;
        }else if(IndikatorPagu === 'kegiatan'){
            //console.log(dataIndikatorNew);
            await setIndikator({...indikator,parent:idParent,kode:IndikatorPagu});
            const paguNow = await dataIndikatorNew.find(row=>parseInt(row.id_indikator) === parseInt(idParent));
            for (const data of dataIndikatorNew) {
              
                const cariPagu = await data.child.filter(row=>parseInt(row.parent) === parseInt(idParent));
                if(cariPagu.length !== 0){
                    await cariPagu.forEach(row => {
                        totalPagu += parseInt(row.perencanaan);
                    });   
                }
            }
            totalPagu = await parseInt(paguNow.perencanaan) - totalPagu;
        }else if(IndikatorPagu === 'sub_kegiatan'){
            let dataPagu=[];
            let paguNow;
            await setIndikator({...indikator,parent:idParent,kode:IndikatorPagu});

            const rekursifData = async(dataIndikatorNew,idParent) =>{
                    const dataPagu=[];
                    for (const row of dataIndikatorNew) {
                        const findIndikator = await row.child.filter(row=>parseInt(row.parent) === parseInt(idParent));
                        if(findIndikator.length > 0){
                            dataPagu.push(...findIndikator);
                        }else{
                             const recursiveResult = await rekursifData(row.child, idParent);
                             dataPagu.push(...recursiveResult);
                             // rekursifData(row.child,idParent);
                        }
                    };
                    return dataPagu;
            }
            const result = await rekursifData(dataIndikatorNew,idParent)
            dataPagu.push(...result);

            for (const data of dataIndikatorNew) {
                    // ========= find pagu yang dipilih agar mendapatkan nilai perencanaan ==============================================
                    let findPaguSelect = await data.child.find(row => parseInt(row.id_indikator) === parseInt(idParent));
                    if (findPaguSelect !== undefined) {
                        paguNow=findPaguSelect;
                    } 
            }

            if(dataPagu.length > 0){
                for(const row of dataPagu){
                    totalPagu += parseInt(row.perencanaan);
                }
            }
            // console.log(dataPagu);
            
            totalPagu = await parseInt(paguNow.perencanaan) - totalPagu;
        }
        
         totalPagu = totalPagu;
        setSisaPagu(totalPagu);
        setNewIndikator(IndikatorPagu);
        setOpen(true);
        handleClose2();
      };
    
      const handleClose = async() => {
        await setOpen(false);
        await setIndikator({...indikator,program:'',
            kegiatan:'',
            sub_kegiatan:'',
            satuan:'',
            perencanaan:'',
            parent:'',
            kode:'',
            kode_bidang:''
        });
      };


       //=================Menu Item ============================
       const [anchorEl,setAnchorEl] = useState(null);
       const [idPendanaan,setIdPendanaan] = useState('');
       const [indikatorData,setIndikatorData] = useState('');
       // const open = Boolean(anchorEl);

       const handleClick = (event)=>{
             const { id_pendanaan,indikator } = event.currentTarget.dataset;
             setIdPendanaan(id_pendanaan || '');
             setIndikatorData(indikator);
             anchorEl === null ? setAnchorEl(event.currentTarget):setAnchorEl(null);
       }

       const handleClose2 = async() => {
           await setAnchorEl(null);
       };

       //==== select Bidang =============

       useEffect(()=>{
         dispatch(getBidang());
       },[dispatch]);

       const bidang = useSelector(state => state.Master.bidang);

       //===================================

       const renderParent = (dataIndikatorNew) =>{
        if (dataIndikatorNew && Array.isArray(dataIndikatorNew)) {
            return (
                dataIndikatorNew.map((row,index) => (
                    <React.Fragment 
                        key={row.id_indikator}
                        >
                        <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCells align='left' >{row.program !== '' && index+1}</TableCells>
                        <TableCells align='left' colSpan={row.program !== '' ? 3 : 1} style={{ display: row.program !== '' ? 'table-cell' : 'none' }} >
                            {row.program !=='' && row.program}
                        </TableCells>
                        <TableCells align='left'  colSpan={row.kegiatan !== '' ? 3 : 1} style={{ display: row.kegiatan !== '' ? 'table-cell' : 'none',paddingLeft:'15vw' }}>
                            { row.kegiatan !== '' && index+1 +".  "+ row.kegiatan}</TableCells>
                        <TableCells align='left' colSpan={row.sub_kegiatan !== '' ? 3 : 1} style={{ display: row.sub_kegiatan !== '' ? 'table-cell' : 'none', paddingLeft:'30vw' }} >
                            { row.sub_kegiatan !== '' && index+1 +".  "+ row.sub_kegiatan}</TableCells>
                        <TableCells align='left' >{ row.sub_kegiatan !== '' && row.satuan}</TableCells>
                        <TableCells align='left' >{rupiahChange(row.perencanaan)}</TableCells>
                        {/* <TableCells align='left' ><Delete sx={{ color: 'red' }} onClick={(e)=>deleteHandler(row.id_satuan)} /></TableCells> */}
                        <TableCells align='left' >
                          {row.sub_kegiatan === '' && (
                            <>
                            <IconButton
                                id={`menu-button${row.id_indikator}`}
                                data-id_pendanaan={row.id_indikator}
                                data-indikator = {row.program !== '' ? 'kegiatan' : 'sub_kegiatan'}
                                aria-controls={Boolean(anchorEl) ? `menu-button${row.id_indikator}`:undefined}
                                aria-haspopup="true"
                                aria-expanded={Boolean(anchorEl) ? 'true': undefined}
                                onClick={handleClick} >
                                <MoreVert />
                            </IconButton>
                                <Menu
                                    id={`menu-drop${row.id_indikator}`}
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose2}
                                    MenuListProps={{'aria-labelledby':`menu-button${row.id_indikator}`}} >
                                        <MenuList sx={{ width: 170, maxWidth: '100%' }}>
                                                <MenuItem onClick={()=>handleClickOpen(indikatorData,idPendanaan)} disableRipple >
                                                    <ListItemIcon>
                                                        <LibraryAdd />
                                                    </ListItemIcon>Add Kegiatan
                                                </MenuItem>
                                            </MenuList>
                                </Menu>
                            </>
                          ) }   
                            </TableCells>
                        </TableRow>
                        {renderParent(row.child)}
                    </React.Fragment>
                    
                ))
        )
            }else{
                return null
                }
       }



       const [satuanSelect,setSatuanSelect] = useState('');

       useEffect(()=>{
            dispatch(getSatuan());
       },[dispatch]);

       const satuan = useSelector(state => state.Satuan.satuan);

       const handleSatuan = async(event) =>{
            await setSatuanSelect(event.target.value);
            setIndikator({...indikator,satuan:event.target.value});
       }
       

  return (
    <>

    {/* ================= select pendanaan ==================================== */}
        <Paper sx={{ width: '100%', overflow: 'hidden', p: 2 }}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography variant="h6" sx={{ mb: 1 }} color="initial">
                Select Pendanaan
            </Typography>
            </Grid>
            <Grid item xs={5}>
                <TextField
                    autoFocus
                    select
                    label="Pendanaan"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={pendanaan.id_pendanaan}
                    onChange={pendanaanChange}
                    helperText="Select Pendanaan"
                >
                {dataIndikator.map((row) => (
                    <MenuItem key={row.id_pendanaan} value={row.id_pendanaan}>
                        {row.pendanaan}
                    </MenuItem>
                ))}
                </TextField>
            </Grid>
            <Grid item xs={5}>
                    <React.Fragment >
                        <TextField
                            autoFocus
                            select
                            label="Sumber Pendanaan"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={pendanaan.id_sumber_dana}
                            onChange={sumberChange}
                            helperText="Select Sumber Pendanaan"
                        >
                    {sumberPendanaan.map((row) => (
                            <MenuItem key={row.id_pendanaan} value={row.id_pendanaan}>
                                {row.pendanaan}
                            </MenuItem>
                    ))}
                    </TextField>
                    </React.Fragment>
                
            </Grid>
            <Grid item xs={2}>
                <Button variant="contained" onClick={selectDanaHandler} >Select Pendanaan</Button>
            </Grid>
        </Grid>
        </Paper>
    {/* ============================================================================================== */}

    
    </>
  )
}

export default IndikatorKinerja