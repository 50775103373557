import { Paper,Grid,Divider, TableContainer, Table, TableRow,TableHead,TableCell
  ,TablePagination,TableBody,styled,TextField, Typography, Menu, MenuItem, IconButton, MenuList, ListItemIcon } from '@mui/material'
import React,{useState,useEffect} from 'react';
// import {Link} from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { getUser,deleteUser } from '../store/user';
import {MoreVert,WhatsApp} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function createData(id_user,username,password,nama){
  return {id_user,username,password,nama};
}

const Search = styled(TextField)({
  padding: '10px'
})



const DataUser = () => {
  
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useSelector(state => state.User.user);

  useEffect(()=>{

    dispatch(getUser());
    
  },[dispatch]);

  const rowsData = [];

  const [newData,setNewData] = useState([]);
  
  user.forEach((item,index)=>{
    rowsData.push(createData(item.id_user,item.username,item.password,item.nama))
  });

  const searchData = async(val) =>{
    if(val.length > 0){
       await setNewData(rowsData.filter((i)=>{
                                              let pattern = new RegExp(val, 'gi') //setting regex utk case sensitive
                                              return i.nama.match(pattern)
                                              }));
    }else{
        await setNewData([])
    }
    
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  //=================Menu Item ============================
  const [anchorEl,setAnchorEl] = useState(null);
  const [telepon,setTelepon] = useState('');
  const [idUser,setIdUser] = useState('');
  // const open = Boolean(anchorEl);

  const handleClick = (event)=>{
    //  console.log(event.currentTarget);
        const { telepon,id_user } = event.currentTarget.dataset;
        setTelepon(telepon || '');
        setIdUser(id_user || '');
        //console.log(anchorEl !== null);
        anchorEl === null ? setAnchorEl(event.currentTarget):setAnchorEl(null);
      }
  const handleClose = async() => {
       await setAnchorEl(null);
      };


  
  // const whatsappOpen = async(number) =>{
  //     let firstChar = number.charAt(0);
  //     let newNumber;
  //     if(firstChar === '0'){
  //        newNumber = "62"+ number.slice(0);
  //     }
  //     await window.open(`https://api.whatsapp.com/send?phone=${newNumber}`,'_blank');
  //     await handleClose();
  // }

  const Edit = async(id_user) =>{
    navigate(`/coba/${id_user}`);
    //console.log(id_user);
  }
  const Delete = async(id_user) =>{
    await handleClose();
    await dispatch(deleteUser(id_user));
    await dispatch(getUser());
    // await handleClose();
    //await dispatch(getUser);
  }




  return (
    <>
    <Typography variant="h4" sx={{mb:4}} color="initial">
      Data User
    </Typography>
    <Paper sx={{width: '100%', overflow:'hidden'}}>
      <Grid container spacing={2}>
        {/* <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
          <Button component={Link} to="Input" variant="outlined" sx={{m:2}}>Add Package</Button>
        </Grid> */}
        <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
          <Typography sx={{m:2,fontSize:20}}>User Data</Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Search
              variant='outlined'
              placeholder='search...'
              type='search'
              size='small'
              onInput={(e) => searchData(e.target.value)}
            />
        </Grid>
      </Grid>
      <Divider />
    <TableContainer sx={{maxheight:440}} >
        <Table stickyHeader aria-label="sticky table" id="table-to-xls" >
            <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Password</TableCell>
                  <TableCell>Nama</TableCell>
                  <TableCell>Option</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {(newData.length > 0 ? newData : rowsData).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => (
                  <TableRow
                  hover
                    key={row.id_user}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" xs={{midWidth:50}} scope="row">
                      {index+1}
                    </TableCell>
                    <TableCell component="th" xs={{midWidth:400}} scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.password}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.nama}
                    </TableCell>
                    <TableCell component="th" scope="row" >
                      <IconButton 
                          id={`menu-button${index}`}
                          data-telepon={row.username}
                          data-id_user={row.id_user}
                          aria-controls={Boolean(anchorEl) ? `menu-button${index}`:undefined}
                          aria-haspopup="true"
                          aria-expanded={Boolean(anchorEl) ? 'true': undefined}
                          onClick={handleClick} >
                          <MoreVert />
                      </IconButton>
                      <Menu
                            id={`menu-drop${index}`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            MenuListProps={{'aria-labelledby':`menu-button${index}`}}
                            
                          >
                            <MenuList sx={{ width: 150, maxWidth: '100%' }}>
                                {/* <MenuItem onClick={(e)=>whatsappOpen(telepon)} disableRipple >
                                      <ListItemIcon>
                                        <WhatsApp />
                                      </ListItemIcon>WhatssApp
                                    </MenuItem> */}
                                    <MenuItem onClick={(e)=>Edit(idUser)} disableRipple >
                                      <ListItemIcon>
                                        <WhatsApp />
                                      </ListItemIcon>Edit
                                    </MenuItem>
                                    <MenuItem onClick={(e)=>Delete(idUser)} disableRipple >
                                      <ListItemIcon>
                                        <WhatsApp />
                                      </ListItemIcon>Delete
                                    </MenuItem>
                              </MenuList>
                      </Menu>
                    </TableCell>
                    
                </TableRow>
              ))}
            </TableBody>
        </Table>
    </TableContainer>
    <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </Paper>
    </>

  )
}

export default DataUser