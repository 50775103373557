import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, ListItemIcon, Menu, MenuItem, MenuList, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { formatRupiah, rupiah } from '../../function/FormatRupiah';
import { useDispatch, useSelector } from 'react-redux';
import { LibraryAdd, MoreVert } from '@mui/icons-material';
import { createRealisasi,getIndikatorKinerja } from '../../store/manage';
import { getSelectPendanaan } from '../../store/master';


const TableCells = styled(TableCell)({
  border: "1px solid black",
});

const TableTop = styled(TableCell)({
  border: "1px solid black",
  color:'#ffffff',
  fontWeight:'Bold',
  fontSize:'20px'
});

const KegiatanAnggaran = () => {
  //  const [sumberDana,setSumberDana] = useState([]);
   const [dataPagu,setDataPagu] = useState([]);
   const [realisasiInput,setRealisasiInput] = useState({pelaksanaan:'',id_indikator:'',id_pendanaan:'',tgl_input:''});
   const [triwulan,setTriwulan] = useState({triwulanNow:'',tahun:0});

   const kode_bidang = localStorage.getItem('kode_bidang') !== null ? localStorage.getItem('kode_bidang') : '';
   
   const dispatch = useDispatch();

  const pendanaanSelected = useSelector(state => state.Master.selectPendanaan); 

  const tahun = useSelector(state=>state.User.tahun);

  // ==== set pendanaan dari database =======
  useEffect(()=>{
    dispatch(getSelectPendanaan(tahun)); 
},[]);


//======== set Triwulan data ============
const getYearAndMonth = () =>{
  const dateNow =  new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' }).slice(0,10);
  const [bulan,tahun] = dateNow.split('/');
  return {
    bulan : parseInt(bulan,10),
    tahun : parseInt(tahun,10)
  }
}

// ================ mencari data triwulan ===========================
useEffect(()=>{
  const {bulan,tahun} = getYearAndMonth();
  const dataTriwulan = Math.ceil(bulan / 3);
  setTriwulan({triwulanNow:dataTriwulan,tahun:tahun});
},[]);


const changeTriwulan = async(e)=>{
  await setTriwulan({...triwulan,triwulanNow:e.target.value});
}

useEffect(()=>{
  if (pendanaanSelected && pendanaanSelected?.length > 0) {
    dispatch(getIndikatorKinerja({ id_pendanaan: pendanaanSelected[0]?.id_pendanaan, kode_bidang: kode_bidang, triwulan: triwulan.triwulanNow }));
  }else{
    dispatch(getSelectPendanaan(tahun)); 
  }
},[]);

// ==================================================================
  
   const getIndikator = useSelector(state=>state.Manage.indikator);
   const [callCount, setCallCount] = useState(0);

    useEffect(() => {
      if (dataPagu.length === 0 && callCount < 10) {
        try {
           //const sumberDanaLocalStorage = JSON.parse(localStorage.getItem('sumberDana'));
           //===== panggil data============
           setCallCount(prevCount => prevCount + 1);
             //dispatch(getIndikatorKinerja({ id_pendanaan: sumberDanaLocalStorage.id_pendanaan }));
             pendanaanSelected?.length > 0 && dispatch(getIndikatorKinerja({ id_pendanaan: pendanaanSelected[0]?.id_pendanaan,kode_bidang:kode_bidang,triwulan:triwulan.triwulanNow }));
             setDataPagu(getIndikator);
              if(getIndikator.length > 0){
                setCallCount(10);
              }
        } catch (error) {
           console.error("Error dalam request:", error);
        }
     }
  }, [dataPagu,callCount,dispatch,getIndikator]);
   


  //=================Menu Item ============================
   const [anchorEl,setAnchorEl] = useState(null);
  //  const [idIndikator,setIdIndikator] = useState('');
   const [selectIndikator,setSelectIndikator] = useState([]);
   const handleClick = (event)=>{
      const { id_indikator,id_pendanaan,sub_kegiatan,satuan,perencanaan } = event.currentTarget.dataset;
      const newData = {
        id_indikator: id_indikator || '',
        id_pendanaan: id_pendanaan || '',
        sub_kegiatan: sub_kegiatan || '',
        satuan: satuan || '',
        perencanaan: perencanaan || ''
      };
      setSelectIndikator(newData);
      anchorEl === null ? setAnchorEl(event.currentTarget):setAnchorEl(null);
  }

    const handleClose2 = async() => {
        await setAnchorEl(null);
    };

    const handleClose = () => {
      setOpen(false);
    };

  // ====================== Open Dialog ====================================
    const [open, setOpen] = useState(false);
      const handleClickOpen = async() =>{
        setRealisasiInput({...realisasiInput,id_indikator:selectIndikator.id_indikator,id_pendanaan:selectIndikator.id_pendanaan});
        await setOpen(true);
        await handleClose2();
      }


    const submitRealisasi = async() =>{
      let tanggalNow = await new Date().toLocaleString().slice(0,9);
      let [bulan,tanggal,tahun] = await tanggalNow.split('/');
      tanggalNow = await `${String(tanggal).padStart(2, '0')}/${String(bulan).padStart(2, '0')}/${tahun}`;
      await setRealisasiInput({...realisasiInput,tgl_input:tanggalNow});
      await dispatch(createRealisasi(realisasiInput));
      await dispatch(getIndikatorKinerja({ id_pendanaan: realisasiInput.id_pendanaan,kode_bidang:kode_bidang,triwulan:triwulan.triwulanNow }));
      // await panggilIndikator(realisasiInput.id_pendanaan);
      await setRealisasiInput({...realisasiInput,pelaksanaan:''});
      await handleClose();
    }

    useEffect(() => {
     // console.log('dataPagu sebelum diperbarui:', dataPagu);
      setDataPagu([]);
      // console.log('dataPagu setelah diperbarui:', dataPagu);
      setDataPagu(getIndikator);
      
    }, [getIndikator]);



   const renderIndikator = (data) =>{
    return(
          data.map((row,index) => (
            <React.Fragment key={row.id_indikator}>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCells align='left' >{row.program !== '' && index+1}</TableCells>
                <TableCells align='left' >{row.program}</TableCells>
                <TableCells align='left' >{row.kegiatan !== '' && index+1 +".  "+ row.kegiatan}</TableCells>
                <TableCells align='left' >{row.sub_kegiatan !== '' && index+1 +".  "+ row.sub_kegiatan}</TableCells>
                <TableCells align='left' >{row.satuan}</TableCells>
                <TableCells align='left' >{rupiah(row.perencanaan)}</TableCells>
                <TableCells align='left' >{rupiah(row.realisasi)}</TableCells>
                <TableCells align="center" sx={{fontWeight:'bold',fontSize:'15px'}} >{row.persentase+'%'}</TableCells>
                <TableCells>
                {row.kode === 'sub_kegiatan' && (
                  <>
                    <IconButton
                      id={`menu-button${row.id_indikator}`}
                      data-id_indikator={row.id_indikator}
                      data-id_pendanaan={row.id_pendanaan}
                      data-sub_kegiatan = {row.sub_kegiatan}
                      data-satuan = {row.satuan}
                      data-perencanaan = {row.perencanaan}
                      aria-controls={Boolean(anchorEl) ? `menu-button${row.id_indikator}`:undefined}
                      aria-haspopup="true"
                      aria-expanded={Boolean(anchorEl) ? 'true': undefined}
                      onClick={handleClick} >
                      <MoreVert />
                    </IconButton>
                      <Menu
                          id={`menu-drop${row.id_indikator}`}
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose2}
                          MenuListProps={{'aria-labelledby':`menu-button${row.id_indikator}`}} >
                              <MenuList sx={{ width: 170, maxWidth: '100%' }}>
                                      <MenuItem onClick={handleClickOpen} disableRipple >
                                          <ListItemIcon>
                                              <LibraryAdd />
                                          </ListItemIcon>Add Realisasi
                                      </MenuItem>
                                  </MenuList>
                      </Menu>
                  </>
                )}
                </TableCells>
              </TableRow>
              {renderIndikator(row.child)}
            </React.Fragment>
        ))
    )
   }

  return (
    <>
      <Typography variant="h4" sx={{mb:4}} color="initial">
        Kegiatan Anggaran
      </Typography>
      <Paper sx={{width: '100%', overflow:'hidden'}}>
      <Grid container spacing={2}>
          <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-start">
            <Typography sx={{m:2,fontSize:20}}>User Data</Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <InputLabel id="demo-simple-select-label">Triwulan</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={triwulan.triwulanNow}
                label="Triwulan"
                onChange={changeTriwulan}
              >
                <MenuItem value={1}>Triwulan 1</MenuItem>
                <MenuItem value={2}>Triwulan 2</MenuItem>
                <MenuItem value={3}>Triwulan 3</MenuItem>
                <MenuItem value={4}>Triwulan 4</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider />
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow sx={{backgroundColor:'#195BF1'}}>
                    <TableTop align="center">#</TableTop>
                    <TableTop align="center">Program</TableTop>
                    <TableTop align="center">Kegiatan</TableTop>
                    <TableTop align="center">Sub Kegiatan</TableTop>
                    <TableTop align="center">Satuan</TableTop>
                    <TableTop align="center">Perencanaan</TableTop>
                    <TableTop align="center">Realisasi Anggaran</TableTop>
                    <TableTop align="center">Persentase</TableTop>
                    <TableTop align="center">Aksi</TableTop>
                </TableRow>
                </TableHead>
                <TableBody>
                  {renderIndikator(dataPagu)}

                  <TableRow>
                    <TableCells colSpan={9}></TableCells>
                  </TableRow>
                  
                </TableBody>
            </Table>
            </TableContainer>
      </Paper>

    {/* ====================== dialog ============================== */}
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
          <DialogTitle>Create Pagu Anggaran </DialogTitle>
            <DialogContent>
              
              <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography>
                        Anggaran Perencanaan Tersisa : {rupiah(selectIndikator.perencanaan)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                        Sub Kegiatan : {selectIndikator.sub_kegiatan}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <TextField
                        autoFocus
                        label="Realisasi"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={realisasiInput.pelaksanaan}
                        onChange={(e)=>{setRealisasiInput({...realisasiInput,pelaksanaan:formatRupiah(e.target.value)})}}
                        sx={{marginBottom:'20px'}}
                      />
                  </Grid>
              </Grid>
            </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={submitRealisasi}>Send</Button>
          </DialogActions>
        </Dialog>
    </>
  )
}

export default KegiatanAnggaran