import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import qs from 'qs';

export const loginUser = (data)=>async(dispatch)=>{
    try {
        const res = await axios.post(`${process.env.REACT_APP_URL}login_user`,qs.stringify(data));
        await localStorage.setItem('token',res.data[0].token);
        await dispatch(set_login(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const logoutUser = () =>async(dispatch)=>{
    await localStorage.removeItem('isLogin');
    await localStorage.removeItem('hak_akses');
    await localStorage.removeItem('kode_bidang');
    await localStorage.removeItem('tahun');
    await localStorage.removeItem('token');
    await dispatch(set_login([]));
}


export const handleTahun = (tahun) =>async(dispatch)=>{
        try {
            const savedTahun = await localStorage.getItem('tahun');
            const selectedTahun = savedTahun ? savedTahun : tahun;
            
            if (selectedTahun !== 0) {
              await localStorage.setItem('tahun', selectedTahun);
              await dispatch(set_tahun(selectedTahun));
            }else{
                await dispatch(set_tahun(selectedTahun));
            }
        } catch (error) {
            console.log(error);
        }
}

export const handleTahunNew = (tahun) =>async(dispatch)=>{
    const selectedTahun = await tahun;
    // Simpan 'tahun' ke localStorage
    await localStorage.setItem('tahun', selectedTahun);
   
    await dispatch(set_tahun(selectedTahun));
}


//====================================================================================================================================


export const createUser = (data) => async()=>{
    try {
        await axios.post(`${process.env.REACT_APP_URL}create_user`,qs.stringify(data));
        return;
    } catch (error) {
        console.log(error);
    }
}

export const getUser = () => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}get_user`);
        return dispatch(get_user(res.data));
        
    } catch (error) {
       console.log(error); 
    }
}

export const getUserId = (id_user) => async(dispatch)=>{
    try {
        const res = await axios.get(`${process.env.REACT_APP_URL}get_userId/${id_user}`);
        return dispatch(get_userId(res.data));
        
    } catch (error) {
       console.log(error); 
    }
}

export const deleteUser = (id_user) => async (dispatch) =>{
    try {
        await axios.delete(`${process.env.REACT_APP_URL}delete_user/${id_user}`);
        return dispatch(delete_user(id_user));
    } catch (error) {
        console.log(error);
    }
}

export const updateUser = (data) => async(dispatch)=>{
    try {
        await axios.post(`${process.env.REACT_APP_URL}update_user`,qs.stringify(data));
        //return dispatch(updateUser());
    } catch (error) {
        console.log(error);
    }
}

export const authToken  = (res) =>{
    if(res.data?.error === 'Not Authenticated' || res.data?.error === 'Internal Server Error'){
        return { status: 'error', error: res.data?.error };
    }
    return null;
}

const User = createSlice({
    name:'user',
    initialState:({user:[],userId:[],loginStatus:[],tahun:0}),
    reducers:{
        set_login(state,action){
            state.loginStatus = action.payload;
        },
        set_tahun(state,action){
            state.tahun = action.payload;
        },
        get_user(state, action){
            state.user = action.payload;
        },
        get_userId(state, action){
            state.userId = action.payload;
        },
        delete_user(state,action){
            //console.log(action.payload);
            const user = state.user.filter(index => parseInt(index.id_user) !== parseInt(action.payload));
            state.user = user;
        }
    }
});

const {set_login,set_tahun,get_user,delete_user,get_userId} = User.actions;

export default User;