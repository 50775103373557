import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'

const DialogUser = ({open,closeDialog,bidang,bidangNew,handleChange,submitUser}) => {

        const onHandleChange = (fieldName) => (e) => {
            handleChange({ ...bidangNew, [fieldName]: e.target.value });
        };

  return (
    <>
        <Dialog open={open} fullWidth maxWidth="md" onClose={closeDialog}>
        <DialogTitle>Add Data User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            data-id="username"
            label="Username"
            type="text"
            fullWidth
            variant="outlined"
            onChange={onHandleChange('username')}
          />
        <TextField
            autoFocus
            margin="dense"
            id="Password"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            onChange={onHandleChange('password')}
          />
          
          <FormControl sx={{ mt: 2}} fullWidth>  
            <InputLabel id="demo-simple-select-label">Akses : </InputLabel>
            <Select
                labelId="demo-simple-select-label"
                value={bidangNew.hak_akses}
                label="Age"
                onChange={onHandleChange('hak_akses')}
            >
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'user'}>User</MenuItem>
            </Select>
          </FormControl>

          { bidangNew.hak_akses === 'user' || bidangNew.hak_akses === '' ?(
                                    <FormControl sx={{ mt: 2}} fullWidth>  
                                    <InputLabel id="bidang">Bidang / UPTD</InputLabel>
                                    <Select
                                        labelId="bidang"
                                        data-id="bidang"
                                        value={bidangNew.kode_bidang}
                                        label="Age"
                                        onChange={onHandleChange('kode_bidang')}
                                        
                                    >
                                        {
                                            bidang?.map((item)=>(
                                                <MenuItem key={item.id_bidang} value={item.kode_bidang}>{item.nama_bidang}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
          ) : null }


        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={submitUser}>Send</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DialogUser