import {
  Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
  } from "@mui/material";
  import React, { useState, useEffect } from "react";
  import { createCapaianKinerja, getSelectPendanaan } from "../../store/master";
  import { createInputCapaian, getCapaian } from "../../store/manage";
  import { useDispatch, useSelector } from "react-redux";
  // import ButtonMenuCapaian from "../../component/ButtonMenuCapaian";
  // import DialogCapaian from "../../component/DialogCapaian";
  import ButtonInputCapaian from "../../component/ButtonInputCapaian";
  import DialogInputCapaian from "../../component/DialogInputCapaian";
  // import makeStyles from '@mui/styles';
  import { useReactToPrint } from 'react-to-print';
import { Print } from "@mui/icons-material";
  
  const TableCells = styled(TableCell)({
    border: "1px solid black",
  });

  const ButtonReport = styled(Button)(({theme})=>({
    borderRadius: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
    margin:'10px 10px',
    cursor:'pointer'
  }));
  
  const CapaianReport = () => {
  
    const dispatch = useDispatch();
  
    const [capaian, setCapaian] = useState([]);
    const [indexCap,setIndexCap] = useState({total_keseluruhan:0,status:'BELUM TUNTAS'});
  
    const [triwulan,setTriwulan] = useState({triwulanNow:'',tahun:0});
  
    const kode_bidang = localStorage.getItem('kode_bidang') !== null ? localStorage.getItem('kode_bidang'):'';
  
    const [capaianInput, setCapaianInput] = useState({
      id_capaian:"",
      id_indikator: "",
      jenis: "",
      total_terlayani: "",
    });
  
    const dataCapaian = useSelector((state) => state.Manage.capaian);
  
  const pendanaanSelected = useSelector(state => state.Master.selectPendanaan); 
  
  const tahun = useSelector(state=>state.User.tahun);
  
  // ==== set pendanaan dari database =======
  useEffect(()=>{
    dispatch(getSelectPendanaan(tahun)); 
  },[]);
  
  //======== set Triwulan data ============
  const getYearAndMonth = () =>{
    const dateNow =  new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' }).slice(0,10);
    const [bulan,tahun] = dateNow.split('/');
    return {
      bulan : parseInt(bulan,10),
      tahun : parseInt(tahun,10)
    }
  }
  
  // ================ mencari data triwulan ===========================
  useEffect(()=>{
    const {bulan,tahun} = getYearAndMonth();
    const dataTriwulan = Math.ceil(bulan / 3);
    setTriwulan({triwulanNow:dataTriwulan,tahun:tahun});
  },[]);
  
  
    const [callCount, setCallCount] = useState(0);
     useEffect(() => {
       if (capaian.length === 0 && callCount < 15) {
         try {
           // const sumberDanaLocalStorage = JSON.parse(localStorage.getItem('sumberDana'));
            //===== panggil data============
            setCallCount(prevCount => prevCount + 1);
              //dispatch(getCapaian({ id_pendanaan: sumberDanaLocalStorage.id_pendanaan }));
              pendanaanSelected.length > 0 && dispatch(getCapaian({ id_pendanaan: pendanaanSelected[0]?.id_pendanaan,kode_bidang:kode_bidang, triwulan:triwulan.triwulanNow}));
              setCapaian(dataCapaian);
               if(capaian.length > 0){
                 setCallCount(15);
               }
         } catch (error) {
            console.error("Error dalam request:", error);
         }
      }
   }, [capaian,callCount,dataCapaian,triwulan]);
  
   const changeTriwulan = async(e)=>{
      //console.log(e.target.value);
      await setTriwulan({...triwulan,triwulanNow:e.target.value});
  }
  
  useEffect(()=>{
      pendanaanSelected.length > 0 && dispatch(getCapaian({ id_pendanaan: pendanaanSelected[0]?.id_pendanaan,kode_bidang:kode_bidang, triwulan:triwulan.triwulanNow}));
  },[triwulan])
  
    //=================Menu Item ============================
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectIndikator, setSelectIndikator] = useState([]);
    const handleClick = (event) => {
      const { id_indikator,id_capaian, id_pendanaan, jenis, kegiatan } = event.currentTarget.dataset;
     // console.log(event.currentTarget.dataset);
      const newData = {
        id_indikator: id_indikator || "",
        id_pendanaan: id_pendanaan || "",
        id_capaian: id_capaian || "",
        jenis: jenis || "",
        kegiatan:kegiatan || ""
      };
      setSelectIndikator(newData);
      anchorEl === null ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
    };
  
    const handleClose2 = async () => {
      await setAnchorEl(null);
    };
  
    // ====================== Open Dialog ====================================
    const [open, setOpen] = useState(false);
    const handleClickOpen = async () => {
      setCapaianInput({
        ...capaianInput,
        id_capaian:selectIndikator.id_capaian,
        id_indikator: selectIndikator.id_indikator,
        jenis: selectIndikator.jenis,
      });
      await setOpen(true);
      await handleClose2();
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleChangeCapaian = (newValue) => {
      setCapaianInput({ ...capaianInput, total_terlayani: newValue });
    };
  
    const submitCapaian = async() => {
         await dispatch(createInputCapaian({capaian:capaianInput,id_pendanaan:selectIndikator.id_pendanaan}));
        //  console.log(capaianInput);
         //await dispatch(createInputCapaian(capaianInput));
          await handleClose();
    };
  
    useEffect(() => {
      // console.log('dataPagu sebelum diperbarui:', dataPagu);
      setCapaian([]);
      // console.log('dataPagu setelah diperbarui:', dataPagu);
      setCapaian(dataCapaian);
      
    }, [dataCapaian]);
  
    useEffect(() => {
      let total_persentase = 0;
    
      for (let item of capaian) {
        total_persentase += parseInt(item.persentase_kegiatan);
      }
    
      if (capaian.length > 0) {
        const average = total_persentase / capaian.length;
        setIndexCap(prevState => ({ ...prevState, total_keseluruhan: parseFloat(average.toFixed(2)) }));
      } else {
        //console.log('Tidak ada data capaian');
      }
    }, [capaian,dataCapaian]);
    
    
  
    const renderCapaian = (data) => {
      return data.map((row, index) => (
        <React.Fragment key={index + 1}>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCells>{row.kode === "kegiatan" ? index + 1 : ""}</TableCells>
            <TableCells align="left">
              {row.kode === "kegiatan"
                ? row.kegiatan
                : index + 1 + ". " + row.sub_kegiatan}
            </TableCells>
            <TableCells>
              {row.kode === "sub_kegiatan" ? row.satuan : ""}
            </TableCells>
            <TableCells colSpan={row.kode === "kegiatan" ? 3 : 1}>
              {row.kode === "sub_kegiatan" && row.total_dilayani}
            </TableCells>
            {row.kode === "sub_kegiatan" ? (
                <>
                  <TableCells>{row.total_terlayani}</TableCells>
                  <TableCells>{row.belum_terlayani}</TableCells>
                </>
            ) : null}
            <TableCells>
            {row.kode === "kegiatan" ? row.persentase_kegiatan+"%" : row.persentase_layanan+"%" }  
            </TableCells>
          </TableRow>
          {row.kode === "kegiatan" && (
            <>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCells>{""}</TableCells>
                <TableCells>
                  PERSENTASE PENCAPAIAN PENERIMA LAYANAN DASAR (80%)
                </TableCells>
                <TableCells>{""}</TableCells>
                <TableCells>Jumlah Total Yang Harus Dilayani</TableCells>
                <TableCells>Jumlah Total Yang Terlayani</TableCells>
                <TableCells>Yang Belum Terlayani</TableCells>
                <TableCells> { row.persentase_80+ "%" }</TableCells>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCells>{""}</TableCells>
                <TableCells> A. JUMLAH YANG HARUS DILAYANI :</TableCells>
                <TableCells>{"orang"}</TableCells>
                <TableCells>
                  {row && row.layanan && row.layanan.total_dilayani}
                </TableCells>
                <TableCells>{row && row.layanan && row.layanan.total_terlayani}</TableCells>
                <TableCells>{row && row.layanan && row.layanan.belum_terlayani}</TableCells>
                <TableCells>{row && row.layanan && row.layanan.persentase_layanan+"%"}</TableCells>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCells>{""}</TableCells>
                <TableCells align="left" colSpan={5}>
                  PERSENTASE PENCAPAIAN MUTU MINIMAL LAYANAN DASAR (20%)
                </TableCells>
                <TableCells>{row.persentase_20 + "%"}</TableCells>
              </TableRow>
              <TableRow>
                <TableCells>{""}</TableCells>
                <TableCells>	B. Jumlah Mutu Barang / Jasa / SDM</TableCells>
                <TableCells></TableCells>
                <TableCells align="left">
                  Jumlah warga negara yang berhak mendapat layanan
                </TableCells>
                <TableCells align="left">
                  Jumlah Mutu Yang Terlayani
                </TableCells>
                <TableCells align="left">
                  Mutu Yang Belum Terlayani
                </TableCells>
                <TableCells align="left">
                  {row.persentase_sub_kegiatan + "%" }
               </TableCells>
               </TableRow>
            </>
          )}
          {renderCapaian(row.mutu)}
        </React.Fragment>
      ));
    };



    const tableRef = React.useRef();
    const handlePrint = useReactToPrint({
      content: () => tableRef.current,
    });
  
  
  
    return (
      <>
        <Typography variant="h4" sx={{ mb: 4 }} color="initial">
          Report Capaian Kinerja
        </Typography>
        <Paper ref={tableRef} sx={{ width: "100%", overflow: "hidden" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={3}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              
              <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                <InputLabel id="demo-simple-select-label">Triwulan</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={triwulan.triwulanNow}
                  label="Triwulan"
                  onChange={changeTriwulan}
                >
                  <MenuItem value={1}>Triwulan 1</MenuItem>
                  <MenuItem value={2}>Triwulan 2</MenuItem>
                  <MenuItem value={3}>Triwulan 3</MenuItem>
                  <MenuItem value={4}>Triwulan 4</MenuItem>
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h5">
                Report Capaian Kinerja Tahun {tahun}
              </Typography>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <ButtonReport
                  variant="outlined"
                  onClick={handlePrint}
                  startIcon={<Print />}
                >
                  Print
              </ButtonReport>
            </Grid>
          </Grid>
          <Divider />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCells align="center">#</TableCells>
                  <TableCells align="center">Indikator Kinerja</TableCells>
                  <TableCells align="center">Satuan</TableCells>
                  <TableCells align="center" colSpan={3}>Indikator Capaian</TableCells>
                  <TableCells align="center">Total Pencapaian</TableCells>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCells>{"(1)"}</TableCells>
                  <TableCells>{"(2)"}</TableCells>
                  <TableCells>{"(3)"}</TableCells>
                  <TableCells colSpan={3}>{"(4)"}</TableCells>
                  <TableCells>{"(5)"}</TableCells>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCells>{""}</TableCells>
                  <TableCells align="left">
                    Kategori Index Capaian SPM
                  </TableCells>
                  <TableCells align="left">
                  </TableCells>
                  <TableCells align="center" colSpan={3}>
                    Belum Tuntas
                  </TableCells>
                  <TableCells>{indexCap.total_keseluruhan +"%"}</TableCells>
                </TableRow>
                {renderCapaian(capaian)}
                <TableRow>
                  <TableCells colSpan={8}>{""}</TableCells>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
  
        <DialogInputCapaian
          open={open}
          kegiatan={selectIndikator.kegiatan}
          handleClose={handleClose}
          submitCapaian={submitCapaian}
          handleChange={handleChangeCapaian}
        />
      </>
    )
  }
  
  export default CapaianReport;