import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import qs from 'qs';


export const getIndikatorKinerja = (data) => async(dispatch)=>{
    try {
        const res = await axios.post(`${process.env.REACT_APP_URL}manage/get_indikator_kinerja`,qs.stringify(data));
        return dispatch(get_realisasi(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const createRealisasi = (data) => async(dispatch)=>{
    try {
        data.pelaksanaan = data.pelaksanaan.replace(/[^0-9]/g,'');
        await axios.post(`${process.env.REACT_APP_URL}manage/create_realisasi`,qs.stringify(data));
        let kodeBidangNew = await localStorage.getItem('kode_bidang') ? localStorage.getItem('kode_bidang') : '';
        await dispatch(getIndikatorKinerja({id_pendanaan:data.id_pendanaan,kode_bidang:kodeBidangNew}));
        return;
    } catch (error) {
        console.log(error);
    }
}

export const getCapaian = (data) =>async(dispatch)=>{
    try {
        const res = await axios.post(`${process.env.REACT_APP_URL}manage/get_capaian_kinerja`,qs.stringify(data));
        return dispatch(set_capaian(res.data));
    } catch (error) {
        console.log(error);
    }
}

export const createInputCapaian = (data) =>async(dispatch) => {
    try {
        //console.log(data);
        await axios.post(`${process.env.REACT_APP_URL}manage/create_input_capaian`,qs.stringify(data.capaian));
        await dispatch(getCapaian({id_pendanaan:data.id_pendanaan}));
    } catch (error) {
        console.log(error);
    }
}


const Manage = createSlice({
    name:'manage',
    initialState:({indikator:[],capaian:[]}),
    reducers:{
        get_realisasi(state,action){
            state.indikator = action.payload
        },
        set_capaian(state,action){
            state.capaian = action.payload;
        }

    }
});

const {get_realisasi,set_capaian} = Manage.actions;

export default Manage;
