import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";

const DialogIndikator = ({open,handleClose,rupiahChange,sisaPagu,indikatorHandle,indikatorHandleBidang,handleInputChange,newIndikator,indikator,submitProgram,satuan,handleSatuan,satuanSelect,bidang}) => {
  const setIndikator = (e) =>{
    const label = e.target.labels[0].textContent;
      if(label === 'Program'){
        indikatorHandle({...indikator,program:e.target.value});
      }else if(label === 'Kegiatan'){
        indikatorHandle({...indikator,kegiatan:e.target.value});
      }else if(label === 'Sub Kegiatan'){
        indikatorHandle({...indikator,sub_kegiatan:e.target.value});
      }    
  }

  const setIndikatorBidang = (e) =>{
        indikatorHandleBidang({ ...indikator, kode_bidang: e.target.value });
  }

  return (
    <>
      {/* =========================== Dialog ================================== */}

      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <DialogTitle>Add Program</DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              Sisa Pagu : {rupiahChange(sisaPagu)}
            </Grid>
            {newIndikator === "program" && (
              <>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    label="Program"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={setIndikator}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl sx={{ m: 1, minWidth: 400 }} size="medium">
                    <InputLabel id="demo-simple-select-label">
                      Pilih Bidang / UPTD :
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={indikator.kode_bidang}
                      label="Triwulan"
                      onChange={setIndikatorBidang}
                    >
                      {bidang?.map((row) => (
                        <MenuItem key={row.id_bidang} value={row.kode_bidang}>
                          {row.nama_bidang}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {newIndikator === "kegiatan" && (
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  label="Kegiatan"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={setIndikator}
                />
              </Grid>
            )}
            {newIndikator === "sub_kegiatan" && (
              <>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    label="Sub Kegiatan"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={setIndikator}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Satuan
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={satuanSelect}
                      label="Age"
                      onChange={handleSatuan}
                    >
                      {satuan.map((item) => (
                        <MenuItem key={item.id_satuan} value={item.satuan}>
                          {item.satuan}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              <TextField
                autoFocus
                label="Perencanaan"
                type="text"
                fullWidth
                variant="standard"
                value={indikator.perencanaan}
                onChange={(e) => handleInputChange(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={submitProgram}>Send</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogIndikator;
