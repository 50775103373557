import React,{useState,useEffect} from "react";
import { ThemeProvider } from "@mui/material";
import Theme from './Theme';
import Base from './layout/Base'
import Routernav from "./Routernav";
import { useDispatch, useSelector } from "react-redux";
import { handleTahun } from "./store/user";

function App() {

  const dispatch = useDispatch();

  const loginStatus = useSelector(state => state.User.loginStatus);

  const [nowLogin,setNowLogin] = useState(loginStatus[0]?.islogin);

  useEffect(()=>{
    //console.log(loginStatus[0]?.islogin);
    if(loginStatus[0]?.islogin){ //jika kondisi state isLogin True maka NowLogin True
      setNowLogin(loginStatus[0]?.islogin); 
    }else if(loginStatus[0]?.islogin !== true ){
      setNowLogin(false);
      if(localStorage.getItem('isLogin') === 'true'){ //jika terjadi refresh
        setNowLogin(true);
      }
    }

  },[setNowLogin,loginStatus,nowLogin]);


  // ============================ set Tahun ====================================

  const tahun = useSelector(state=>state.User.tahun);

  useEffect(()=>{
    const tahunDefault = new Date().getFullYear();
    if(tahun === 0){
      dispatch(handleTahun(tahunDefault));
    }
  },[tahun]);


  return (
    <ThemeProvider theme={Theme}>
      <Base isLoggedIn={nowLogin} >
        <Routernav isLoggedIn={nowLogin} />
      </Base>
    </ThemeProvider>
  );
}

export default App;
