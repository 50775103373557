import { Check, Close, Delete, Send } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

const DialogPembatalanIndikator = ({openPembatalan,handleClosePembatalan,submitPembatalan}) => {
  return (
    <>
        <Dialog
            open={openPembatalan}
            onClose={handleClosePembatalan}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {""}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Apa Anda Yakin Melakukan Pembatalan ?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant="outlined" color="error" startIcon={<Close />} onClick={handleClosePembatalan}>Batal</Button>
            <Button variant="outlined" color="primary" startIcon={<Send />} onClick={submitPembatalan} autoFocus>
                Lanjutkan
            </Button>
            </DialogActions>
        </Dialog>
    
    </>
  )
}

export default DialogPembatalanIndikator